export default {
  tempo: "Tempo",
  needAccount:
    "You must have a Tempo account to use Time Credits on this activity",
  time: "Time",
  credits: "Credits",
  creditsNumInFront: "Credit",
  timeCredits: "Time Credits",
  timeCreditsNumInFront: "Time Credits",
  TCdelivery: "Time Credit Delivery",
  gifted: "Gifted",
  costs: "Costs",
  vary: "Vary",
  not: "Not",
  costsVaries: "Costs Varies",
  needToKnow: "What do I need to know",
  spendTC: "Use Tempo Time Credits",
  findOutMore: "Find Out More",
  loadMore: "Load More",
  groupsBookInAdvance: "Groups need to book in advance",
  groupsDontBookInAdvance: "Groups don't need to book in advance",
  maxGroupSize: "The maximum group size is",
  VolunteeringWithMe: "Currently Volunteering with me",
  back: "Back",
  activities: "Activities",
  details: "Details",
  shareActivity: "Share activity",
  pickOneOption: "Pick one option to use Tempo Time Credits with your Tempo ID",
  pickOneOptionShort: "Pick one option to use Time Credits",
  turnUp: "Turn up at the venue",
  callEmail: "Call or email to book",
  getOnlineBooking: "Get an online booking code",
  turnUpWithID: "Turn up at the venue with your Tempo ID",
  noNeedToBook: "No need to book",
  arriveAtVenue: "Arrive at the venue",
  checkAvailability: "Remember to check availability and restrictions",
  tellThemActivity:
    "Tell them the activity you want to access with your Time Credits",
  giveID: "Give them your Tempo ID",
  automaticallyDeducted:
    "The Time Credits will be automatically deducted from your account",
  haveFun: "Have fun",
  bookAdvance: "Book in advance",
  contactVenueByPhone: "Contact the venue by phone",
  contactVenueByEmail: "Contact the venue by email",
  orByEmail: "or by email",
  email: "Email",
  forMoreInfo: "for more information",
  includeDates: "include dates times and the number of people",
  theyWillTakeTC:
    "They will take the Time Credits from your account and confirm your booking",
  furtherInfo: "Further information",
  getAccessCodeOnline: "Get a booking code online with your time credits",
  numberOfPeople: "Number of people",
  adults: "Adults",
  over16: "Over 16",
  children: "Children",
  under16: "Under 16",
  clickGetAccess:
    "Click 'Get booking code' below. Time Credits will be deducted from your account and you will receive your booking codes with instructions on how to use them. They will also be emailed to you.",
  notEnoughTC: "You do not have enough Time Credits in your account",
  thisWillCost: "This will cost",
  getCode: "Get booking code",
  yourAccessCodes: "Your Booking Codes",
  youveGotCodesPartOne: "You've got {CUSTOM1} booking codes",
  youveGotCodesPartTwo:
    "Please make a note of these codes for your records if you wish. They have also been emailed to you",
  redeemCodes: "How do I use my booking codes",
  redeemCodesDetails:
    "Book your tickets online with your codes as a discount on checkout. Each code is valid for 1 adult booking.",
  visitVenueWebsite:
    "Visit the venue website and enter your access code as a discount code when you checkout to book your cinema tickets",
  backToSpend: "Go back to Use Time Credits",
  unsuccessful: "Unsuccessful",
  ohNoShort: "Oh no! This transaction was not approved",
  ohNo: "Oh no! This transaction was not approved. You do not have enough Time Credits",
  goBack: "Go back",
  currentRoles: "Current roles",
  currentlyHiringFor: "We're currently hiring for the following roles",
  lookingForHelp: "We're always looking for help with",
  lotsOfWays:
    "There are lots of ways to get involved. Get in touch to let us know your availability and how you can help",
  volunteerHere: "Volunteer here",
  getInTouch: "Get in touch",
  backToEarn: "Go back to Earn Tempo Time Credits",
  multipleLocations: "Multiple locations",
  online: "Online",
  createMemberAccount: "Create a Tempo Time Credit Volunteer account",
  mustHaveAccount:
    "You must have a Tempo account to use Time Credits for this activity",
  createAccount: "Create account",
  alreadyHaveAccount: "Already have a Volunteer account",
  logIn: "Log in",
  about: "About",
  noActivitiesListed: "No activities currently listed",
  viewAccessibility: "View Accessibility Information for this venue",
  TempoTCEarned: "Tempo Time Credits Earned",
  TempoTCSpent: "Tempo Time Credits Used",
  noSpendOptions:
    "At the moment there are no options to use Time Credits for this activity. Please come back soon",
  contactVenueBefore: "Contact the venue before you want to go",
  singleAccessCode:
    "Here is your booking code! Please make a note of this code for your records if you wish. It has also been emailed to you",
  noDetails: "No details provided",

  //   ERROR MESSAGES
  // maxNumPerMember:
  //   "You have reached the limit of {CUSTOM1} tickets for this activity",
  // maxReached:
  //   "You have reached the limit of {CUSTOM1} tickets for this activity",
  notEnoughCodes:
    "There are only {CUSTOM1} tickets remaining for this activity. Please try again with a smaller number",
  notEnoughTCs: "You do not have enough Time Credits in your account",
  multipleAccountConfirmationRequired: "Multiple account confirmation required",

  //   TIMES
  anytime: "Anytime",
  today: "Today",
  tomorrow: "Tomorrow",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
  Sunday: "Sunday",
  January: "January",
  February: "February",
  March: "March",
  April: "April",
  May: "May",
  June: "June",
  July: "July",
  August: "August",
  September: "September",
  October: "October",
  November: "November",
  December: "December",
  // weekdaysShort
  Sun: "Sun",
  Mon: "Mon",
  Tue: "Tue",
  Wed: "Wed",
  Thu: "Thu",
  Fri: "Fri",
  Sat: "Sat",

  // Log in page
  typeEmail: "Type your email here",
  password: "Password",
  typePassword: "Type your password here",
  forgotPassword: "Forgot password",
  recaptcha:
    "This site is protected by reCAPTCHA and the Google | Privacy Policy | and | Terms of Service | apply",
  clickToAcceptTimeCredits: "To accept Time Credits, click here",
  welcomeToTC: "Welcome to Tempo Time Credits",
  earnTempoTCs: "Earn Tempo Time Credits | by giving your time to good causes",
  spendTempoTCs: "Use Tempo Time Credits | to have fun with family and friends",

  // sign up
  signUp: "Sign up",
  alreadyHaveAccountLogIn: "Already have an account? | Log in",
  firstName: "First name",
  typeFirstName: "Type your first name",
  lastName: "Last name",
  typeLastName: "Type your last name",
  typeFullName: "Type your full name",
  typeEmailAddress: "Type email address here",
  passwordContain: "Password must contain",
  min8characters: "A minimum of 8 characters",
  capitalLetter: "One capital letter",
  lowercaseLetter: "One lowercase letter",
  oneNumber: "One number",
  createPassword: "Create a password here",
  nameOfOrg: "Name of your organisation",
  typeYourOrg: "Type your organisation name here",
  typeOfOrg: "Type of organisation",
  whatFocus: "What do you focus on?",
  selectUpToThree: "Select up to three",
  selectAsManyLike: "Select as many as you like",
  region: "Region",
  selectRegion: "Select your region",
  localAuthority: "Local authority",
  selectLocalAuthority: "Select your local authority",
  phoneNumber: "Phone number",
  typeYourPhoneNumber: "Type your phone number here",
  agreeToTerms:
    "I agree to the Tempo | Terms of Use | . By clicking Sign up I acknowledge the Tempo | Privacy Policy",
  mustAgree: "You must agree to our terms and conditions",
  fieldRequired: "This field is required",
  welcome: "Welcome",
  nowPartOfNetwork:
    "You're now part of a national network of organisations using Time Credits to involve people in communities and create positive social change",
  giveOutTCToSayThanks:
    "Give Out Tempo Time Credits to say thank you instantly",
  giveOutTempoTC: "Give out Tempo Time Credits",
  toSayThanks: "To say thank you instantly",
  checkTCBalance: "Check your Tempo Time Credits balance",
  knowHowMany: "So you always know how many you have left",
  invitePeople: "Invite people easily",
  toEarnTC:
    "to earn Tempo Time Credits, check their Time Credits balance, and find fun ways to use them",
  letsGo: "Let's go",
  giveOutTC: "Give out Time Credits",
  transactionsWith: "Transactions with",
  noRecords: "No records to show",
  acceptTempoTC: "Accept Tempo Time Credits",
  getMoreTC: "Get more Time Credits",
  earnTempoTC: "Earn Tempo Time Credits",
  viewMembers: "View My Volunteers",
  inviteMembers: "Invite a new Volunteer",
  myMembers: "My Volunteers",
  balance: "Balance",
  noActivitiesFound: "No activities found",
  menu: "Menu",
  welcomeBack: "Welcome back",
  month: "Month",
  thisMonth: "This month",
  year: "Year",
  allTime: "All time",
  tcGivenOut: "Tempo Time Credits Given Out",
  tcSpentByMembers: "Tempo Time Credits used by your volunteers",
  tcGivenToOthers: "Tempo Time Credits given to others by your volunteers",
  newMembers: "New volunteers signed up",
  name: "Name",
  joined: "Joined",
  tcEarned: "Time Credits Earned",
  tcSpent: "% Time Credits Used",
  remove: "Remove",
  rejoin: "Re-join",
  viewMore: "View more",
  tcHistory: "Tempo Time Credit History",
  tcBalance: "Time Credit Balance",
  date: "Date",
  activity: "Activity",
  numberOfTC: "# Time Credits",
  activityDate: "Activity / Date",
  type: "Type",
  givenOut: "Given out",
  received: "Received",
  exchanged: "Exchanged",
  pending: "Pending",
  members: "Volunteers",
  signedUpAtMoment: "Signed up at the moment",
  nameJoined: "Name / Join Date",
  exportData: "Export data",
  reAdd: "Re-add",
  recordStat: "{CUSTOM1} of {CUSTOM2} Record(s)",
  chooseCategory: "Choose a category",
  somethingElse: "Something else",
  onlyUse: "Only use if other categories don't apply",
  addActivityName:
    "Would you like to add the activity’s name or date for your records",
  optional: "Optional",
  dateLastTc: "Date Last Issued Time Credits",
  dateLastGiveOutTc: "Date last gave out time credits",
  egCoffee: "e.g. Coffee Club or Gardening Group",
  startDate: "Start date",
  endDate: "End date",
  loading: "loading",
  nowChoose: "Now choose who to give Tempo Time Credits to",
  recogniseHours: "Tempo Time Credits recognise hours of time",
  oneTimeCreditCanBeGiven:
    "1 Time Credit can be given out for a minimum of 1 hour of volunteering. To manage your Time Credit balance, you can give out less Time Credits than the number of hours someone has given",
  whoGiveTCTo: "Who would you like to give Tempo Time Credits to",
  selectMembers: "Select your volunteer(s)",
  selectAll: "Select all",
  howManyTCsGiveOut: "How many Time Credits would you like to give out",
  noMembers: "No volunteers added so far",
  totalTC: "Total Time Credits",
  remainingBalance: "Your remaining balance",
  sayThankYou: "Say thank you and give out Tempo Time Credits",
  applyAll: "Apply all",
  woohoo: "Woohoo",
  youHaveThankedYourMembers:
    "You have thanked your volunteers and given them their Time Credits. You now have {CUSTOM1} Time Credits remaining in your account",
  viewDashboard: "View Dashboard",
  giveOutMore: "Give Out More",
  mostPopularInUK: "Most Popular in the UK",
  sendYourUnique: "Send out your unique invite link",
  copy: "Copy",
  successfullyCopied: "Successfully copied",
  sendUniqueLinkExplanation:
    "Send your unique invite link to your volunteers by email, text, social media or even by writing it down! This link always stays the same - send it out as many times to as many people as you would like",
  membersNeedEmailAddressExplanation:
    "Volunteers will need an email address to create an online account with Tempo. When they create an account they will be able to receive Tempo Time Credits from you, check their balance easily and find fun ways to use their Tempo Time Credits",
  whatIfNoEmail:
    "What do I do if my new Volunteer doesn’t have an email address",
  clickToFindOut: "Click here to find out",
  inviteMemberNoEmail:
    "Invite a new Volunteer who doesn't have an email address",
  membershipCardExplanation:
    "If a volunteer does not have an email address they can access Tempo Time Credits with a Tempo ID card. Please fill in their details below",
  dateOfBirth: "Date of birth",
  ok: "OK",
  cancel: "Cancel",
  postcode: "Postcode",
  typePostcode: "Type postcode here",
  typePhoneNumber: "Type phone number here",
  typeOfActivity: "Type of activity",
  wantToGoBack: "I want to go back",
  registerMember: "Register volunteer",
  areYouSureEmail: "Are you sure your volunteer doesn't have an email address",
  ifTheyDoHaveEmail:
    "If they do have an email address they should sign up using your unique invite link on the previous page so they have access to their own account to check their balance and see their activity",
  imSure: "I'm sure",
  membershipID: "Tempo ID",
  nowUseMembershipID:
    "Great! This Member can now use their Tempo ID to access Tempo Time Credits",
  whenArriveAtSpendVenue:
    "When they arrive at one of our Recognition Partners they will need to tell them their Tempo ID",
  pleaseWriteMembershipID:
    "Please write their Tempo ID on one of the cards provided and give it to them",
  understandAndGiveMembershipID:
    "I understand and have given the Member their Tempo ID",
  search: "Search",
  exportDate: "Export Date",
  dateActivity: "Date Activity",
  yesterday: "Yesterday",
  getMoreTempoTC: "Get More Tempo Time Credits",
  howManyTCs: "How many Time Credits would you like",
  buyTempoTc: "buy tempo Time Credits",

  discussThisFurther:
    "If you would like to discuss this further please contact us on 0292 056 6132",
  requestReceived: "Request received",
  requestLogged:
    "Your request has been logged. Tempo will process it within the next 2 working days. You will receive an email to let you know the outcome. To get in touch with us about this – please contact us on 0292 056 6132",
  requestLogged2:
    "Your request has been logged. Tempo will process it within a maximum of 5 working days. You will receive an email to let you know the outcome. To get in touch with us about this please use the Help function or call 0292 056 6132",
  letPeopleKnow: "Let people know they can earn Time Credits with you",
  provideDetailsAboutOrg:
    "Provide details about your organisation and the types of volunteering activities you run so potential volunteers can find you and get in touch",
  addVersionInWelsh: "Add a version in Welsh Language",
  orgName: "Organisation name",
  organisation: "Organisation",
  typeOrgName: "Type your organisation here",
  typeOrgTypeHere: "Type your organisation type here",
  focuses: "Focuses",
  upToThree: "up to three",
  whereCanPeopleEarn: "Where can people earn Time Credits with you",
  weDontHaveAddress: "Our volunteering is done remotely",
  hideFullAddress: "Hide our full address (only show town/city)",
  addressDescription:
    "Your address helps local volunteers find you. If your volunteering is done remotely, or you don't want to share your address online, please check the relevant box below.",
  anythingElse: "Anything else they should know",
  addressLine1: "Address Line 1",
  addressLine2: "Address Line 2",
  townCity: "Town / City",
  maxCharacters: "Max {CUSTOM1} characters",
  addAnotherLocation: "Add another location",
  typeAddress: "Type address",
  typeTownCity: "Type town / city",
  tellPotentialVolunteersAboutOrg:
    "Tell potential volunteers about your organisation",
  typeHere: "Type here",
  charactersUsed: "characters used",
  howShouldPeopleGetInTouch:
    "How should people get in touch with you about earning Time Credits",
  contactEmail: "Contact email",
  warning: "Warning",
  typeEmailHere: "Type email here",
  typeFirstNameHere: "Type first name here",
  typeLastNameHere: "Type last name here",
  contactNumber: "Contact number",
  websiteSignUpLink: "Website / Sign up Link",
  typeUrlHere: "Type url here",
  typeFacebookHere: "Type Facebook url here",
  facebookLink: "Facebook link",
  twitterLink: "Twitter link",
  typeTwitterHere: "Type Twitter url here",
  dragLogoHere: "Drag logo here or click",
  fileSize: "File size max {CUSTOM1}",
  dragImageHere: "Drag image here or click",
  minDimensions: "Min dimensions {CUSTOM1}",
  addSpecificActivities:
    "Add specific activities that you give Time Credits out for",
  egBreakfastClub:
    "e.g Breakfast Club or Shifts on Reception. Add as many as you would like",
  activityName: "Activity Name",
  TCNumber: "Number of Time Credits",
  typeActivityName: "Type an activity name",
  addDatesTimesSkills: "Add dates, times or skills needed",
  addAnotherActivity: "Add another activity",
  tickToMakePublic: "Tick to make your profile public",
  saveChanges: "Save changes",
  viewProfile: "View profile",
  profileSaved: "Profile saved",
  selectTypesActivities: "Select the types of activities volunteers can do",
  selectAsMany: "Select as many as you need",
  accountDetails: "Account details",
  emailAddress: "Email Address",
  resetPassword: "Reset password",
  orgDetails: "Organisation details",
  administrators: "Administrators",
  administratorsExplanation:
    "Administrators can do everything you can do, apart from add further administrators. You have added {CUSTOM1} administrators. You are allowed to add {CUSTOM2} more",
  dateLastIssued: "Date late issued Time Credits",
  ofRecords: "{CUSTOM1} of {CUSTOM2} Record(s)",
  addNewAdmin: "Add new administrator",
  add: "Add",
  woohooSent: "Woohoo! You've sent them a sign-up link by email",
  areYouSureRemoveAdmin: "Are you sure you want to remove this administrator",
  willBeDeletedAsAdmin: "Will be deleted as an administrator",
  signUpSent: "Sign up link sent",
  membershipIdExplainer:
    "You’ll need this if you’re using your Time Credits at a venue, or making a booking in advance. If you’d like us to post you a Tempo ID card, please send us your address using the Help button.",
  receivedEmailWithSignUpLink:
    "{CUSTOM1} has received an email with a sign up link to set their password. Please ask them to check their inbox",
  ohNoNotApproved: "Oh no! This transaction was not approved",
  didYouEnterCorrectly: "Did you enter their Tempo ID correctly? You entered",
  pleaseAskCustomer:
    "Please ask the Customer to check their Time Credits balance before",
  tryingAgain: "trying again",
  success: "Success",
  dontForgetInternalBooking:
    "Don't forget to run this through your internal booking or till system so they can enter on the day of the activity",
  transactionApproved:
    "The transaction has been approved and the Time Credits have been deducted from the customers account",
  askCustomerForID: "Ask the Customer for their Tempo ID",
  typeMembershipID: "Type their Tempo ID",
  canBeFound:
    "This can be found on their online account or on their Tempo ID Card",
  chooseActivity: "Choose activity",
  hasRestrictions: "Has restrictions",
  wantToAddSomething:
    "Want to add something here? Contact Tempo on help@wearetempo.org or 0292 056 6132 and we will add it for you",
  provideAsMuchDetail: "Provide as much detail as possible",
  tcPerPersonHere: "Time Credits per person here",
  tcPerPerson: "Time Credits per person",
  pickNumberOfPeople: "Pick or type number of people",
  wantsYouToBecomeMember:
    " | {CUSTOM1} | wants you to become a Tempo Time Credits Volunteer",
  wantsYouToBecomeAdministrator:
    " | {CUSTOM1} | wants you to become an Administrator for their Tempo Time Credits account",
  confirm16OrOlder: "I confirm I am 16 or older",
  or: "Or",
  iAmUnder16:
    "I am under 16 and I confirm I have the consent of my parent or legal guardian to join Tempo Time Credits",
  pleaseIndicateOver16:
    "Please indicate that you are over 16 or have consent from a parent or guardian to sign up",
  tooShortCustom: "Too short - should be {CUSTOM1} minimum",
  tooShort1: "Too short - should be 1 character minimum",
  tooShort5: "Too short - should be 5 characters minimum",
  aboutYou: "About you",
  atTempoExplanation:
    "At Tempo, we want to reach people from different backgrounds and value everyone's contribution. This means we need to collect information about who is signing up to use Time Credits",
  informationUseExplanation:
    "This information will be used anonymously to understand if Time Credits are relevant and accessible for different groups. | We will never sell your personal information to third parties",
  appreciateYourHelp:
    "We would appreciate your help, but you can skip this step below. Thank you",
  whatGender: "What is your gender",
  haveYouVolunteered: "Have you volunteered before",
  continue: "Continue",
  skipStep: "Skip this step",
  nowAMember: "You're now a Tempo Time Credits Volunteer",
  findWaysToVolunteer: "Find Ways to Volunteer",
  searchForWaysExplanation:
    "Search for ways to help community groups and services in ‘Earn Tempo Time Credits’. As a thank you, you will receive Time Credits direct to your online account",
  checkBalance: "Check your balance",
  youCanCheckTCExplanation:
    "You can check how many Time Credits you have and see where you have earned and used your Time Credits in 'My Time Credits History'",
  enjoyFunActivities: "Enjoy fun activities",
  searchForFunActivitiesExplanation:
    "Search for fun activities you can access with Time Credits in ‘Use your Time Credits’. Use your Tempo ID at the venue, to book in advance or get an online access code",
  youCanEarnTCWith: "You can now earn Tempo Time Credits with",
  dontHaveTempoAccount: "Don't have a Tempo account",
  giftTC: "Gift Tempo Time Credits",
  shareLove: "Share the love",
  youCanGiftExplanation:
    "You can gift your Tempo Time Credits to friends and family",
  myTCHistory: "My Tempo Time Credits History",
  tempoTCHistory: "Tempo Time Credits History",
  myTCBalance: "My Time Credits balance",
  organisationPerson: "Organisation / Person",
  youCanGiftExplanationLong:
    "You can gift your Tempo Time Credits to friends and family. They will receive an email to sign up and collect their Time Credits",
  whatEmail: "What's their email",
  howManyTCsGift: "How many Time Credits would you like to gift",
  confirm: "Confirm",
  sorryNotEnough: "Sorry, you do not have enough Time Credits",
  sorryNotEnoughInYourAccount:
    "Sorry, you do not have enough Time Credits in your account",
  noTransactions: "No transactions yet",
  myAccount: "My account",
  changesSaved: "Changes saved",
  contactTempoToDelete:
    "Contact Tempo if you would like to delete your account",
  termsOfUse: "Terms of use",
  privacy: "Privacy",
  cookies: "Cookies",
  findWaysToGive: "Find ways to give time near me",
  selectCityTown: "Select city / town",
  searchByActivity: "Search by activity",
  searchByCause: "Search by cause",
  next: "Next",
  findFunActivitiesOnline: "Find Fun Activities Online",
  nearby: "Nearby",
  sortBy: "Sort by",
  popularity: "Popularity",
  nearest: "Nearest",
  recentlyAdded: "Recently Added",
  relevance: "Relevance",
  cityTown: "City / town",
  searchByActivityType: "Search by activity type",
  activityType: "Activity Type",
  transactionDate: "Transaction Date",
  transactionDates: "Transaction Dates",
  activityDateSingle: "Activity Date",
  activityDates: "Activity Dates",
  home: "Home",
  earnTC: "Earn Tempo Time Credits",
  earnTCShort: "Earn Time Credits",
  spendTCShort: "Use Time Credits",
  giftTCShort: "Gift Time Credits",
  myTCHistoryShort: "My Time Credits history",
  howTCsWork: "How Time Credits work",
  accountSettings: "Account Settings",
  logOut: "Log out",
  dashboard: "Dashboard",
  acceptTC: "Accept Time Credits",
  earnSpendActivities: "Search Time Credits opportunities",
  venueInsights: "Venue insights",
  offers: "Offers",
  venues: "Venues",
  tcHistoryShort: "Time Credits history",
  recruitVolunteers: "Recruit volunteers",
  insights: "Insights",
  invalidPasswprd: "Invalid password",
  submit: "Submit",
  greatSetUpPassword: "Great, you’ve set up a new password. Keep it safe",
  enterEmailExplanation:
    "Enter the email address associated with your account and we will send you a link to reset your password. Please remember to check your email junk folder",
  sendLink: "Send link",
  invalidEmail: "Invalid email",
  passwordResetLinkSent: "A password reset link has been emailed to you",
  returnToLogIn: "Return to log in",
  tcAccepted: "Time Credits accepted",
  tcAcceptedDuringYear:
    "Time Credits accepted during the current calendar year",
  accepted: "accepted",
  sinceYouJoinedTempo: "Since you joined Tempo",
  uniqueTempoMemberVisits: "Unique Tempo Volunteer visits",
  thisYear: "This year",
  tempoTCTransactions: "Tempo Time Credits transactions",
  newReturningVisitor: "New / Returning visitor",
  new: "New",
  since: "since",
  returning: "Returning",
  exportActivity: "Export Activity",
  cookieExplanation: "This website uses cookies to enhance the user experience",
  findOutMoreCookies: "Find out more about our Cookie Policy",
  iUnderstand: "I understand",
  myBalance: "My balance",
  giftSent: "Gift sent",
  congratulationsGiftReceived:
    "Congratulations! {CUSTOM1} has just received your gift",
  congratulationsEmailSent:
    "Congratulations! An email has been sent to them with a link to sign up and receive their Tempo Time Credits",
  didntReceiveEmail: "Didn't receive the email",
  copyLinkExplanation:
    "You can copy and paste the link below into an email, text or social media message",
  goToHomePage: "Go to home page",
  receivedGift: "You've received a gift",
  receivedTCsFromFriend:
    "You have just received {CUSTOM1} Tempo Time Credits from your friend",
  welcomeBig: "Welcome!",
  logInSo: "Log in so",
  canAddAsAdmin: "can add you as an Administrator of their group",
  canAddYouToGroup: "can add you to their group",
  acceptTCWithoutLogIn: "Accept Time Credits without logging in",
  tcDelivery: "Time Credit Delivery",
  gift: "Gift",
  of: "Of",
  records: "record(s)",
  spend: "spend",
  used: "used",
  use: "use",
  earn: "earn",
  transactionDetails: "Transaction details",
  person: "Person",
  dateOfActivity: "Date of activity",
  to: "to",
  perPerson: "per person",
  logInOnComputer:
    "Log in on a computer to view your entire transaction history",
  translateExport: "Contact Tempo for translation of export",
  reason: "Reason",
  selectVenue: "Select your venue",
  venue: "Venue",
  typeVenue: "Type venue",

  // Zendesk
  yourNameOptional: "Your name (optional)",
  zenEmailAddress: "Email address",
  howCanWeHelpYou: "How can we help you?",
  attachments: "Attachments",
  addUpTo5Files: "Add up to 5 files",
  send: "Send",
  help: "Help",
  translate: "Translate",
  // ERROR MESSAGES
  passwordMustContain:
    "Password must contain min. 8 characters, one capital letter, one lowercase letter and one number",
  invalidPhone: "Invalid phone number",
  invalidRole: "Invalid role",
  invalidUrl: "Invalid url",
  selectParticipantsNumber: "You should select participants number",
  mustAgreeTC: "You must agree to our terms and conditions",
  mustConfirmPermission: "You must confirm permission",
  tooLong100: "Too long - should be 100 characters maximum",
  tooLong500: "Too long - should be 500 characters maximum",
  tooLong50: "Too long - should be 50 characters maximum",
  tooLong7: "Too long - should be 7 characters maximum",
  invalidPostcode: "Invalid postcode",
  membershipIDShouldBe: "Tempo ID should be 8 characters",
  shouldBeMoreZero: "Should be more than zero",
  shouldBeValidType: "Should be valid type",
  spendActivityCannotBeMadeLive:
    "A activity cannot be made live if the activity's end date is in the past",
  oneAboveInvalidEmail: "One of the above is an invalid email",
  shouldBeGreaterThan0OrEmpty:
    "Should be greater than 0, or set empty to clear",
  selectOneItem: "You should select one item at least",
  pageNotFound: "Page not found",
  personDoesntHaveEnoughTC: "This person doesn’t have enough Time Credits",
  notEnoughTimeCredits: "You don’t have enough Time Credits",
  dataNotFound: "Data you have requested not found",
  invalidCredentials: "Invalid credentials",
  accessRouteLogin: "To access this route you need to login first",
  noAccessPermission: "You have no access permission",
  invalidEmailOrPassword: "You have entered an invalid email or password",
  invalidPassword: "You have entered an invalid password",
  missingParameters: "Missing parameters",
  validationError: "Validation error",
  invalidMembershipID: "Tempo ID is invalid",
  activityNotFound: "Activity not found",
  sorrySomeoneSignedUp:
    "Sorry someone with the same details has already been signed up",
  emailAlreadyExists: "A user account with that email already exists",
  emailNotAllowedMultipleUsers:
    "A user account already exists with that email. To sign up please use the password linked with that account.",
  emailAlreadyHasEarnGroup:
    "An account with this email already exists. If you are trying to add a new group to your account, please enter the email address and password for that account above or | click here to log in",
  userWithEmailExists:
    "User with that email address already exists. Please use a different email address",
  emailAddressTaken:
    "Email address taken, you must use a unique email address to manage this earn group",
  spendVenueAlreadyCreated:
    "A venue has already been created with this email address",
  emailNotFound: "Email address not found",
  linkExpired: "Your link has expired or is invalid",
  requestProcessed: "This request has been processed",
  earnCanOnlyAcceptFromProgramme:
    "Earn group can only accept from the programme it's assigned to",
  exceededProgrammeBudget: "You've exceeded the programme budget",
  giftNoLongerAvailable: "This gift is no longer available",
  inviteLinkExpired: "This invite link has expired",
  cannotAcceptOwnGifts: "You can not accept your own gifts",
  invalidProgrammeInviteLink: "Invalid programme invitation link",
  notEnoughBookingCodes: "There are not enough booking codes available",
  noOnlineCodes: "This activity has no online codes",
  reachedCodesLimitL: "You have reached the codes limit",
  priceDoesntMatch: "Price doesn't match",
  staffAlreadyExists: "A staff user account with that email already exists",
  emailDoesntMatchInvite:
    "Your email address doesn't match the one in this invite",
  adminLimitReached:
    "The Administrator limit has already been reached. Please ask the Earn Group to remove an existing Administrator in order for you to sign up",
  notAssignedToEarnGroup: "You are not assigned to any earn group",
  onlyManageOneEarnGroup: "Sorry, you can only manage one earn group at a time",
  emailTakenNeedUnique:
    "Email address taken, you must add a unique email address to be able to manage this programme",
  onlyManageOneProgramme: "Sorry, you can only manage one programme at a time",
  userAlreadyAddedToProgramme: "This user is already added to this programme",
  userAlreadyManagingProgramme:
    "This user is already managing other programmes",
  extProgManagerLimitReached:
    "The External Programme Manager limit has already been reached for this programme. Please contact Tempo to remove one in order for you to be added",
  notAssignedToProgramme: "You are not assigned to any programme",
  notEnoughTCsInBudget:
    "There are not enough Time Credits in the budget. There are [NUMBER] Time Credits available. If you need more please speak to the relevant Tempo staff member",
  internalServerError: "internal server error",
  tooManyRequests: "too many requests",
  shouldProvideAuthorizationName: "Should provide authorization name",
  restrictionPerVolunteerHasReached: "Restriction per volunteer has reached",
  thisEmailIsLinked: "This email is linked with another account",
  enterPasswordToSwitch: "Enter the password for {CUSTOM1} to switch",
  yourNameWillBeUpdated:
    "Your name will be updated to the new account ({CUSTOM1})",
  note: "Note",
  done: "Done",
  error: "error",
  complete: "complete",
  processed: "processed",
  badRequest: "bad request",
  unauthorized: "unauthorized",
  forbidden: "forbidden",
  requestTimeout: "request timeout",
  conflict: "conflict",
  badGateway: "bad gateway",
  serviceUnavailable: "service unavailable",
  gatewayTimeout: "gateway timeout",
  notImplemented: "not implemented",
  paymentRequired: "Payment Required",
  notAcceptable: "Not Acceptable",
  methodNotAllowed: "Method Not Allowed",
  proxyRequired: "Proxy Authentication Required",
  gone: "Gone",
  lengthRequired: "Length Required",
  preconditionFailed: "Precondition Failed",
  requestTooLarge: "Request Entity Too Large",
  unsupportedMediaType: "Unsupported Media Type",
  requestRange: "Requested Range Not Satisfiable",
  expectactionFailder: "Expectation Failed",
  teapot: "I'm a teapot",
  unprocessableEntity: "Unprocessable Entity",
  locked: "Locked",
  failedDependency: "Failed Dependency",
  tooEarly: "Too Early",
  upgradeRequired: "Upgrade Required",
  preconditionRequired: "Precondition Required",
  requestHeaderTooLarge: "Request Header Fields Too Large",
  unavailableLegal: "Unavailable For Legal Reasons",
  httpNotSupported: "HTTP Version Not Supported",
  variantNegotiates: "Variant Also Negotiates",
  insufficientSTorage: "Insufficient Storage",
  bandwidthExceeded: "Bandwidth Limit Exceeded",
  notExtended: "Not Extended",
  networkRequired: "Network Authentication Required",
  searchByPostcode: "Search by postcode",
  searchByKeyword: "Search by keyword",

  mostPopular: "Most popular",
  activitiesMatchLocation: "Activities that match your search near",
  noActivitiesFoundSorry: "Sorry, no activities found",
  findActivitiesNearMe: "FIND FUN ACTIVITIES NEAR ME",
  noVersionInLanguage:
    "Sorry, there is not a version of this listing in your selected language",
  errorCopying: "Sorry, there was an error copying",
  recruitVolunteersPageHeader:
    "LET PEOPLE KNOW THEY CAN EARN TIME CREDITS WITH YOU!",
  provideDetailsAboutOrganisation:
    "Provide details about your organisation and the types of volunteering activities you run so potential volunteers can find you and get in touch",
  english: "english",
  welsh: "welsh",
  earnKeywordsExamples:
    "Example: food, food waste, cooking, cook, chef, dinner",
  earnKeywordsLabel:
    "Please enter any keywords to help with searching, separated by a comma",
  fileMaxSize: `file size max {CUSTOM1}mb`,
  addAnActivity: "Add an activity",
  addWelshVersion: "Add a version in Welsh language yourself",
  onlySignUpIfInvitedEGAdmin:
    "Sorry, currently you can only sign up if you are invited by a group. If there is a group you help manage, please get them to add you as an Administrator",
  selectActivityType: "Select activity type",
  doYouHavePhysicalConditions:
    "Do you have any physical or mental health conditions or illnesses lasting or expected to last for 12 months or more?",
  select: "Select",
  tickAsManyAsApply: "Tick as many as apply",
  WhatIsYourEthnic: "What is your ethnic group?",
  all: "All",
  pickUp3Options: "You can only pick up to 3 options",
  selectActivity: "Select activity",
  selectCause: "Select cause",
  somethingWentWrong: "Something went wrong",
  longitudeLatitudeError:
    "Error getting the latitude and longitude for submitted postcode",
  postcodeNotRecognised: "One of the postcodes entered isn't recognised",
  inputFieldMissing:
    "At least one of the input fields has not been filled in or details entered incorrectly. Please check the form above for more details",
  ifYouBuildIt: "If you build it, they will come",
  buildingThisPart:
    "Our team are working hard building this new part of the platform. It will be here very soon!",
  shallNotPass: "You shall not pass!",
  doNotHavePermission: "Sorry, you do not have permission to view that page",
  bitLost: "Oops, I'm a bit lost!",
  sorryPageDoesntExist: "Sorry, the page you're looking for doesn't exist",
  tooManyRequestsTryAfter: "Too many requests, try after",
  goodNews: "Good news",
  switchAccount: "switch account",
  setupMoreVenues:
    "If you would like to set up a separate login for one or more of your venues, please contact us using the Help button or call 020 8245 3099, Monday-Friday 9am-5pm.",
  spendPartnerAcceptTermsBody:
    "We've noticed it's your first time here so we need to share our | Terms of Use | and our | Privacy Policy | with you. Click Continue to accept these",
  chooseYourVenue: "Choose YOUR Venue",
  selectYourAccount: "Select your account",
  selectAccount: "Select account",
  account: "Account",
  liveActivities: "Live activities",
  draftActivities: "Draft activities",
  viewVenuePage: "View venue page",
  status: "Status",
  view: "View",
  live: "Live",
  draft: "Draft",
  issued: "Issued",
  needHelpWithTranslation:
    "Need help with translation? Get in touch with Tempo using the help button",
  createEnglishVersionOfProfile:
    "You need to create an English version of your profile. If you need help to do this get in touch with Tempo using the help button",
  adminSuccessfullyRemoved: "Administrator successfully removed",
  adminSuccessChangeMainAccount: "Main account holder updated",
  noRecordsToDisplay: "No records to display",
  activityNotLive:
    "At the moment there are no options to use Time Credits for this activity. Please come back soon",

  unavailable: "coming soon",
  restriction: "Restrictions",
  currentlyUnavailable: "Currently unavailable",

  // [capacityReached, perMonthReached, perVolunteerReached, perSlotReached, perSlotReached, perTransactionReached]
  // are used in the code and the keys are generated dynamically
  // Don't delete please
  capacityReached:
    "Sorry the total capacity for this activity has been reached",
  perMonthReached: "Sorry the total capacity for this month has been reached",
  perVolunteerPerMonthReached:
    "Sorry the total capacity per volunteer for this month has been reached",
  perWeekReached: "Sorry the total capacity for this week has been reached",
  perVolunteerPerWeekReached:
    "Sorry the total capacity per volunteer for this week has been reached",
  perVolunteerReached:
    "Sorry the total capacity per volunteer has been reached",
  perSlotReached: "Sorry the total capacity of slot has been reached",
  perTransactionReached:
    "Sorry the total capacity for number of people reached",
  restrictionExists: "This offer has limited availability",
  perMonth: "per month",
  perVolunteerPerMonth: "per volunteer per month",
  perWeek: "per week",
  perVolunteerPerWeek: "per volunteer per week",
  perVolunteer: "per volunteer",
  perSlot: "per slot",
  perTransaction: "per number of people",
  toProceed: "To proceed please enter your name before and click Confirm",
  proceedingTransactionWithError:
    "Proceeding with this transaction will take you over the total allocation this volunteer is allowed",
  searchWithinArea: "Search within area (miles)",
  nationalOffers: "National offers",
  includeAllNationalOffers: "Include all national offers",
  availability: "Availability",
  onlyShowOffersCurrentlyAvailable: "Only show offers currently available",
  clearFilters: "Clear filters",
  searchIn: "Search in",
  addOffer: "Add Offer",
  changeSubmitted: "Changes submitted",
  yourActivityIsCurrentlyBeingReviewed:
    "Your activity will be reviewed by Tempo. We will notify you as soon as it has been approved and is live.",
  activityCreated: "Activity created",
  viewManageOffers: "View & manage offers",
  edit: "Edit",
  earnGroupOffersNote:
    "Here you can create activities to accept Time Credits, subject to Tempo approval. If you want to check how likely it is you’ll be approved call 02920 566 132",
  whoWouldYouLikeToSignUpAs: "Who would you like to sign up as?",
  draftActivityCreatedMessage:
    "Your activity has been created. Click to make it live when you're ready for it to be reviewed by Tempo and published.",
  volunteer: "Volunteer",
  youWouldLikeToOrAlreadyVolunteer:
    "You would like to or already volunteer and earn Tempo Time Credits",
  youWouldYouWouldLikeToSupport:
    "You would like to use Tempo Time Credits to support your venues or volunteering groups",
  county: "County",
  typeCounty: "Type county...",
  searchByLocation: "Search by location",
  proceedingWithTransaction:
    "Proceeding this transaction will take you over the capacity you have allocated to this activity",
  areYouSure: "Are you sure?",
  comingSoon: "coming soon",
  thisActivityWillNeedToBeReApproved:
    "This activity will need to be re-approved so if you are only making a minor change, please contact the team via the Help button on the page.",
  activitySaved: "ACTIVITY SAVED",
  bookingCodesError: "Each booking code can have 50 charaters at most",
  viewActivity: "View Activity",
  activityFormStepError:
    "This offer cannot go live until all steps have been completed",

  basicInformation: "Basic Information",
  provideActivityDetails:
    "Provide the details that will appear on their Activity Listing",
  isOnlineActivity: "Is this an online activity?",
  howManyPersonPerTC: "How many Time Credits per person?",
  makeActivityLive: "Make activity live",
  makeActivityFeatured: "Mark activity as Featured",
  nextStep: "Next step",
  whenUseTC: "When can you use Time Credits?",
  provideActivityDates:
    "Please provide the dates and times when you can use Time Credits for this activity.",

  useTcAnyTime: "You can use Time Credits any time",
  addAnotherDate: "Add another date",
  whenActivityEnd: "When does the activity end?",
  whenActivityEndSubtitle: "optional, use if any time used has an end date?",
  typeNumber: "Type number here",
  totalCapacityLabel: "Total capacity",
  totalCapacityPerMonthLabel: "Total capacity per month",
  totalCapacityPerVolunteerPerMonthLabel:
    "Total capacity per volunteer per month",
  totalCapacityPerWeekLabel: "Total capacity per week",
  totalCapacityPerVolunteerPerWeekLabel:
    "Total capacity per volunteer per week",
  totalCapacityPerVolunteerLabel: "Total capacity per volunteer",
  totalCapacityPerSlotLabel: "Total capacity per slot",
  totalCapacityPerTransactionLabel: "Maximum number of people per transaction",
  createActivity: "Create activity",
  WhatTheyNeedToKnow: "What do they need to know?",
  WhatTheyNeedToKnowMoreInfo:
    "Please provide further information people will need to know.",
  isBookingRequiredForGroups: "Do groups need to book in advance?",
  maxGroupSizeLabel: "Maximum group size",
  howToUseTc: "How to Use Time Credits",
  howToUseTcDetails:
    "Please provide details on the ways you can use Time Credits on this activity.",
  enterBookingCodes:
    "Please enter your booking codes for this activity, each separated by a comma",

  codesDetected: "{CUSTOM1} codes detected",
  codesStatus: " {CUSTOM1} live codes remaining ({CUSTOM2} codes already used)",
  useCodesLink: "Link to use codes",
  useCodesLinkHelper:
    "if included, this will show as a button saying 'Visit the partner website' when the volunteer receives their codes",
  activityNamePlaceholder: "e.g. Fitness Class",
  activitySubtitle: "Activity Subtitle",
  activitySubtitlePlaceholder: "e.g. Yoga / Pilates / BodyPump",
  selectActivitiesTypes: "Select Activity Type(s)",
  aboutActivity: "About the Activity",
  typeWebsiteUrl: "Type website url",
  activityExternalLink: "Activity external link",
  activityImageHelper: "min dimensions 820 x 280, file size max 2mb",
  keywordsPlaceholder: "Example: Gym, Exercise, Sport, Fitness Session",
  keywordsLabel:
    "Please enter any keywords to help with searching, separated by a comma",
  repeatsMonthly: "Repeats monthly on the",
  selectDates: "Select dates",
  selectTimes: "Select times",
  restrictionsOnDates: "Any restrictions on these dates?",
  restrictionsOnDatesPlaceholderHelper:
    "e.g. not available in half term - max 500 characters",
  yes: "Yes",
  no: "No",
  addAnyFurtherInfo: "Add any further information",
  furtherInfoHelper: "e.g. Go to gate 1, Must be aged over 18",
  bookingCodesDetails:
    "Please provide any more details on how they use their booking codes",
  step1: "Basic Information",
  step2: "When can you use Time Credits?",
  step3: "What do they need to know?",
  step4: "How to use Time Credits",
  startTime: "Start time",
  endTime: "End time",
  underReview: "under review",
  selectLocationType: "Offer location type",
  clickToUpload: "Click to upload",
  dragImageHereOrClickToUpload: "Drag image here or click to upload",
  repeatTransactions: "Repeating a previous transaction?",
  repeatTransactionsHelper:
    "If this is a regular transaction you’ve done before, | click here | to select from your recent transactions.",
  rememberToCheck: "Remember to check your list of volunteers",
  rememberToCheckDescription:
    "We can see you are repeating a previous transaction. Please double check your volunteers to make sure you have included all the right people and given them the right Time Credits!",
  noPrevTransactions: "No previous transactions found",
  clickHere: "Click here",
  invite: "Invite",
  thisIsAnInviteToJoinProgrammeAddEarnGroup:
    "This is an invite to join the [PROGRAMME_NAME] Programme. To accept the invite please select whether you want to add an existng group to this programme or create a new one. If you want to find out more call 02920 566 132.",
  selectExistingGroup: "Select existing group",
  createNewGroup: "Create new group",
  toIgnoreAndSkipThis: "To ignore and skip this",
  sitTight: "Sit tight!",
  emailSentToSupportBeInTouch:
    "An email has been sent to support who will be in touch to discuss",
  myGroups: "My groups",
  groupsIntro:
    "Here you can see all the groups you are currently signed up as volunteering with.",
  confirmPermission:
    "I confirm I have the permission of the person I am signing up",
  and: "and",
  shouldBeMoreThanZero: "the number should be greater than 0",
  shouldBeGreaterThanMinValue: "Should be greater than the minimum value",
  wantDifferentAmount: "Want a different amount",
  generateInvoice: "Generate Invoice & Complete Payment",
  pricePlaceholder: "Type number, minimum {CUSTOM1} credits...",
  selectPackage: "Select one of the packages below",
  orderConfirmation: "order confirmation",
  numberOfTimeCreditsAndCost: "Number of Time credits and cost",
  subtotal: "Subtotal",
  amountDue: "Amount Due",
  totalVAT: "Total VAT",
  payByCard: "Pay by card",
  cardNumber: "Card number",
  expiryDate: "Expiry date",
  CVV: "CVV",
  billingAddress: "Billing Address",
  pay: "Pay",
  paymentDue: "Payment Due",
  cancelled: "Cancelled",
  purchased: "Purchased",
  memberName: "Member name",
  givenOutBy: "Time Credits given out by",
  amountPaid: "Amount paid",
  download: "Download",
  downloadInvoice: "Download invoice",
  cancellationReason: "Reason for cancellation",
  agreeToTermsAndConditions: "I agree to the | Terms and Conditions",
  payByBankTransfer: "Want to pay by bank transfer",
  contactUsUsingHelpButton:
    "Please contact us using the Help button or ring 02920 566132",
  needToCancelOrder:
    "If you need to cancel or make changes to this order please contact us using the Help button or ring 02920 566132",
  confirmPaymentError:
    "Unfortunately payment could not be processed. Your card has not been charged.",
  tryAgain: "try again",
  paymentUnsuccessful: "payment unsuccessful",
  paymentSuccessful: "payment successful",
  paymentSuccessfulMessage:
    "Great news, your payment has been taken successfully and your Time Credits will now be issued to you. Check your inbox for a confirmation email with your receipt attached.",
  vatInvoice: "VAT invoice",
  toSaveThisInvoiceClickPrint:
    "To save this invoice, click Print in your browser and for the destination select “Save as PDF” ",
  proformaInvoice: "Proforma Invoice",
  invoiceNumber: "Invoice Number",
  invoiceDate: "Invoice Date",
  description: "Description",
  quantity: "Quantity",
  amount: "Amount",
  GBP: "GBP",
  totalVAT20: "Total VAT 20%",
  timeCreditsPurchase: "Time Credits Purchase",
  tempoTimeCreditsPurchase: "Tempo Time Credits Purchase",
  clickToBookOnPartnerWebsite: "Click here to book on the partner website",
  copyLinkIntoBrowser: "Or copy this link into your browser",
  transactionId: "Transaction ID",
  nearlyThere: "Nearly there",
  toActivateYourAccountPleaseGoToYourInbox:
    "To activate your account please go to your inbox and click the confirmation link in the email we have just sent you.",
  sendAgain: "Send again",
  emailSent: "Email sent",
};
