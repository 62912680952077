import {
  GET_EARN_GROUPS_SUCCESS,
  GET_EARN_GROUPS_FAIL,
  GET_EARN_GROUPS_LOADING,
} from "../types";

import { earnGroups } from "../../api-calls";
import flatten from "../../utils/helpers/flatten";

export const getEarnGroups =
  ({
    isPublic = false,
    keyword,
    language,
    limit,
    location,
    county,
    postcode,
    sortBy,
  }) =>
  async (dispatch) => {
    dispatch({ type: GET_EARN_GROUPS_LOADING });

    const [earnGroupsRes, locationsRes] = await Promise.all([
      await earnGroups.getEarnGroups({
        isPublic,
        keyword,
        language,
        limit,
        skipLimit: !!(location || county || postcode),
        sortBy,
      }),
      earnGroups.getEarnGroupsLocations({ language }),
    ]);
    const { data, error } = earnGroupsRes;
    const locations = locationsRes?.data || data;

    if (!error) {
      const citiesList = [...new Set(flatten(locations.map((e) => e.city)))];
      const countiesList = [
        ...new Set(
          flatten(
            locations.map((e) => e.county.filter((_c) => _c?.length > 0)),
          ),
        ),
      ];
      dispatch({
        type: GET_EARN_GROUPS_SUCCESS,
        payload: {
          earnGroups: data,
          citiesList,
          countiesList,
          location,
          county,
        },
      });

      return data;
    }

    dispatch({
      type: GET_EARN_GROUPS_FAIL,
      payload: error,
    });
  };
