import axios from "axios";
import handleError from "../handle-errors";

const POSTCODE_BASE = "https://api.postcodes.io/postcodes?q=";
const PLACE_BASE = "https://api.postcodes.io/places?q=";
const MULTI_POSTCODES_BASE = "https://api.postcodes.io/postcodes";

const getLocationByPostcode = async ({ query, options = {} }) => {
  try {
    const data = await axios.get(`${POSTCODE_BASE}${query}`);
    return data;
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getLocationByPlace = async ({ query, options = {} }) => {
  try {
    const { data } = await axios.get(`${PLACE_BASE}${query}`);
    return { data };
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

const getLocationsByBulkPostcodes = async ({ query, options = {} }) => {
  try {
    const data = await axios.post(MULTI_POSTCODES_BASE, { postcodes: query });
    return data;
  } catch (error) {
    const err = handleError(error, options);
    return { error: err };
  }
};

export {
  getLocationByPostcode,
  getLocationByPlace,
  getLocationsByBulkPostcodes,
};
