import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Wrapper from "./style";
import * as T from "../Typograpy";
import Icon from "../Icon";
import Loading from "../Loading";
import t from "../../utils/helpers/translator";

const renderActionType = (type, iconText) => {
  if (iconText) {
    return (
      <Icon
        icon={type === "Remove" || type === "Clear" ? "close" : "plus"}
        color={type === "Remove" || type === "Clear" ? "pink" : "cyan"}
        width="22px"
        height="22px"
        margin="0 5px 0 0"
      />
    );
  }
  return (
    <>
      <Icon
        icon={type === "Remove" || type === "Clear" ? "close" : "plus"}
        color={type === "Remove" || type === "Clear" ? "pink" : "cyan"}
        width="22px"
        height="22px"
        margin="0 5px 0 0"
      />
      <T.Body14R>{iconText}</T.Body14R>
    </>
  );
};

const RemoveRejoin = ({
  handleClick,
  showText,
  type,
  loading,
  text,
  iconText,
  language,
  deactivated,
  ...props
}) => {
  const translateText =
    type === "Remove" ? t("remove", language) : t("rejoin", language);
  return (
    <Wrapper deactivated={deactivated} type={type} {...props}>
      <IconButton
        onClick={handleClick}
        type={type}
        disabled={loading || deactivated}
        aria-label="remove or rejoin member"
      >
        {!loading ? renderActionType(type, iconText) : <Loading />}
        {showText && (
          <T.Body14S color="gray3">
            {loading ? t("loading", language) : text || translateText}
          </T.Body14S>
        )}
      </IconButton>
    </Wrapper>
  );
};
export default RemoveRejoin;
