import R from "../../constants/roles";
import {
  GET_LOGGED_IN_USER_INFO_SUCESSS,
  GET_LOGGED_IN_USER_INFO_FAIL,
  LOGIN_SUCESSS,
  LOGIN_FAIL,
  TEMP_LOGIN_SUCESSS,
  TEMP_LOGIN_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  SESSION_REFRESH_SUCCESS,
  SESSION_REFRESH_FAIL,
  SIGN_UP_POST_SUCCESS,
  SIGN_UP_POST_FAIL,
  GET_EARN_GROUP_INFO_BY_TOKEN_SUCCESS,
  GET_EARN_GROUP_INFO_BY_TOKEN_FAIL,
  SIGN_UP_POST_LOADING_START,
  SIGN_UP_POST_LOADING_END,
  LOGIN_LOADING_START,
  LOGIN_LOADING_END,
  UPDATE_MEMBER_PROFILE_LOADING_START,
  UPDATE_MEMBER_PROFILE_LOADING_END,
  UPDATE_MEMBER_PROFILE_SUCCESS,
  UPDATE_MEMBER_PROFILE_FAIL,
  UPDATE_LANGUAGE_LOADING_START,
  UPDATE_LANGUAGE_SUCCESS,
  UPDATE_LANGUAGE_FAIL,
  UPDATE_ACCEPT_TERMS_LOADING,
  UPDATE_ACCEPT_TERMS_SUCCESS,
  UPDATE_ACCEPT_TERMS_FAIL,
  UPDATE_SELECTED_VENUE_LOADING,
  UPDATE_SELECTED_VENUE_SUCCESS,
  UPDATE_SELECTED_VENUE_FAIL,
  RESET_SIGNUP_UP_ERROR,
} from "../types";

const decideLanguage = (props) => {
  const staffRoles = [
    R.TEMPO_STAFF_USER,
    R.TEMPO_STAFF_ADMIN,
    R.TEMPO_STAFF_SUPER,
    R.PROGRAMME_MANAGER,
  ];
  if (staffRoles.includes(props.role)) {
    return props.viewLanguage;
  }
  return props.language;
};

const initialState = {
  isLoggedIn: false,
  isVerified: null,
  isMounted: false,
  id: null,
  supervisorId: null,
  role: null,
  language: localStorage.getItem("language") || "english",
  viewLanguage: localStorage.getItem("language") || "english",
  decideLanguage,
  firstName: null,
  lastName: null,
  acceptedGiftId: null,
  emailVerified: null,
  expires: null,
  lastActive: undefined,
  profile: {
    name: null,
    welshName: null,
    membershipId: null,
    inviteToken: "",
  },
  spendPartner: {
    name: null,
    id: null,
    acceptedTerms: null,
    acceptedTermsLoading: false,
  },
  selectedSpendVenue: {
    id: "",
    role: "",
    language: "",
    email: "",
    firstName: "",
    lastName: "",
    profile: { name: "", welshName: "" },
  },
  spendVenuesCount: null,
  multipleUsers: false,
  selectedActiveUser: false,
  earnGroupInfo: {
    id: null,
    profile: {
      name: "",
      inviteToken: "",
    },
  },
  signupLoading: false,
  loginLoading: false,
  signupError: {},
  loginError: {},

  updateMemberProfileLoading: false,
  programmeId: null,
  programmeName: "",

  // get user info error
  error: {},

  updateSelectedVenueLoading: false,
  updateLanguageLoading: false,
  updateLanguageError: {},
};

const authReducer = (_state = initialState, action) => {
  const state =
    action.type !== SESSION_REFRESH_SUCCESS &&
    action.type !== SESSION_REFRESH_FAIL
      ? { ..._state, lastActive: new Date() }
      : _state;

  switch (action.type) {
    case GET_LOGGED_IN_USER_INFO_SUCESSS:
      return {
        ...state,
        ...action.payload,
        isLoggedIn: action.payload.multipleUsers
          ? action.payload.selectedActiveUser
          : true,
        isMounted: true,
      };

    case GET_LOGGED_IN_USER_INFO_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoggedIn: false,
        isMounted: true,
      };

    case SIGN_UP_POST_SUCCESS:
    case LOGIN_SUCESSS:
      return {
        ...state,
        ...action.payload,
        isVerified: action.payload.multipleUsers
          ? action.payload.selectedActiveUser
          : true,
        isLoggedIn: action.payload.multipleUsers
          ? action.payload.selectedActiveUser
          : true,
        isMounted: true,
        loginError: {},
      };
    case SESSION_REFRESH_SUCCESS:
      return {
        ...state,
        expires: action.payload.expires,
      };
    case SIGN_UP_POST_FAIL:
      return { ...state, signupError: action.payload, isLoggedIn: false };
    case RESET_SIGNUP_UP_ERROR:
      return { ...state, signupError: {} };
    case LOGIN_FAIL:
      return {
        ...state,
        loginError: action.payload,
        isLoggedIn: false,
        isMounted: true,
      };

    case TEMP_LOGIN_SUCESSS:
      return {
        ...state,
        ...action.payload,
        isLoggedIn: true,
        isVerified: false,
        isMounted: true,
        loginError: {},
      };

    case TEMP_LOGIN_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoggedIn: false,
        isMounted: true,
      };

    case LOGOUT_SUCCESS:
      return {
        ...initialState,
        isLoggedIn: false,
        isMounted: true,
        language: action.payload.actualLanguage,
      };

    case LOGOUT_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoggedIn: false,
        isMounted: true,
      };

    case GET_EARN_GROUP_INFO_BY_TOKEN_SUCCESS:
      return {
        ...state,
        earnGroupInfo: action.payload,
      };

    case GET_EARN_GROUP_INFO_BY_TOKEN_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case SIGN_UP_POST_LOADING_START:
      return {
        ...state,
        signupLoading: true,
      };
    case SIGN_UP_POST_LOADING_END:
      return {
        ...state,
        signupLoading: false,
      };

    case LOGIN_LOADING_START:
      return {
        ...state,
        loginLoading: true,
      };
    case LOGIN_LOADING_END:
      return {
        ...state,
        loginLoading: false,
      };

    case UPDATE_MEMBER_PROFILE_LOADING_START:
      return {
        ...state,
        updateMemberProfileLoading: true,
        updateMemberProfileSuccess: false,
      };
    case UPDATE_MEMBER_PROFILE_LOADING_END:
      return {
        ...state,
        updateMemberProfileLoading: false,
      };
    case UPDATE_MEMBER_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        updateMemberProfileSuccess: true,
      };
    case UPDATE_MEMBER_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_LANGUAGE_LOADING_START:
      return {
        ...state,
        updateLanguageLoading: true,
      };
    case UPDATE_LANGUAGE_SUCCESS:
      return {
        ...state,
        language: action.payload.language,
        viewLanguage: action.payload.viewLanguage,
        updateLanguageLoading: false,
      };
    case UPDATE_LANGUAGE_FAIL:
      return {
        ...state,
        updateLanguageLoading: false,
        updateLanguageError: action.payload,
      };

    case UPDATE_ACCEPT_TERMS_LOADING:
      return {
        ...state,
        spendPartner: { ...state.spendPartner, acceptedTermsLoading: true },
      };
    case UPDATE_ACCEPT_TERMS_SUCCESS:
      return {
        ...state,
        spendPartner: {
          ...state.spendPartner,
          acceptedTerms: true,
          acceptedTermsLoading: false,
        },
      };
    case UPDATE_ACCEPT_TERMS_FAIL:
      return {
        ...state,
        spendPartner: {
          ...state.spendPartner,
          acceptedTerms: false,
          acceptedTermsLoading: false,
        },
      };

    case UPDATE_SELECTED_VENUE_LOADING:
      return {
        ...state,
        updateSelectedVenueLoading: true,
        selectedSpendVenue: {},
      };
    case UPDATE_SELECTED_VENUE_SUCCESS:
      return {
        ...state,
        selectedSpendVenue: action.payload,
        updateSelectedVenueLoading: false,
      };
    case UPDATE_SELECTED_VENUE_FAIL:
      return {
        ...state,
        updateSelectedVenueLoading: false,
      };

    default:
      return state;
  }
};

export default authReducer;
