import getDistance from "./getDistance";

const localTypesByBestMatch = [
  "City",
  "Town",
  "Village",
  "Hamlet",
  "Suburban Area",
  "Other Settlement",
];

const sortByLocation = ({
  locationArr,
  listings,
  distanceRange,
  selectedCity,
  sortByDistance,
}) => {
  if (!locationArr || locationArr.length === 0) return [];
  if (!listings) return [];

  let sourceLocation = {};

  if (locationArr.length > 1) {
    const bestMatch = locationArr.sort(
      (a, b) =>
        localTypesByBestMatch.indexOf(a.local_type) -
        localTypesByBestMatch.indexOf(b.local_type),
    );

    sourceLocation = {
      lat: bestMatch[0].latitude,
      long: bestMatch[0].longitude,
    };
  } else {
    // get first location in result
    sourceLocation = {
      lat: locationArr[0]?.latitude || locationArr[0]?.lat, // use lat/long if the geolocation api didnt return results and we set the locationArr to be the found cities, search for `const foundCities = cityFilter(activities);`
      long: locationArr[0]?.longitude || locationArr[0]?.long,
    };
  }

  const activitiesIn50Miles = [];
  listings.forEach((listing) => {
    const { geoLocations = [] } = listing;

    // if they selected a city same as listing's city
    if (listing.city?.includes(selectedCity)) {
      const distance = 0;
      activitiesIn50Miles.push({ ...listing, distance });
    }

    // if activity has multiple addresses get the closest one
    else if (geoLocations.length > 1) {
      const relevantLocations = [];

      geoLocations.forEach((loc) => {
        const distance = getDistance(sourceLocation, loc);
        if (distance <= distanceRange) {
          relevantLocations.push({ ...listing, distance });
        }
      });

      const sortedLocs = relevantLocations.sort(
        (a, b) => a.distance - b.distance,
      );

      if (sortedLocs.length > 0) {
        activitiesIn50Miles.push(sortedLocs[0]);
      }
    }
    // otherwise just check single distance s
    else if (geoLocations.length === 1) {
      const distance = getDistance(sourceLocation, listing.geoLocations[0]);
      if (distance <= distanceRange) {
        activitiesIn50Miles.push({ ...listing, distance });
      }
    }
  });

  return sortByDistance
    ? activitiesIn50Miles.sort((a, b) => a.distance - b.distance)
    : activitiesIn50Miles;
};

export default sortByLocation;
