import { members, groups, transactions } from "../../../api-calls";

import {
  GET_EARN_GROUPS_MEMBERS_SUCCESS,
  GET_EARN_GROUPS_MEMBERS_LOADING,
  GET_EARN_GROUPS_MEMBERS_FAIL,
  UPDATE_EARN_GROUPS_MEMBERS_STATUS_SUCCESS,
  UPDATE_EARN_GROUPS_MEMBERS_STATUS_LOADING,
  UPDATE_EARN_GROUPS_MEMBERS_STATUS_FAIL,
  GET_EARN_GROUPS_MEMBER_BY_ID_SUCCESS,
  GET_EARN_GROUPS_MEMBER_BY_ID_LOADING,
  GET_EARN_GROUPS_MEMBER_BY_ID_FAIL,
  GET_EARN_GROUP_MEMBER_TRANSACTIONS_SUCCESS,
  GET_EARN_GROUP_MEMBER_TRANSACTIONS_LOADING,
  GET_EARN_GROUP_MEMBER_TRANSACTIONS_FAIL,
} from "../../../redux/types";

export const getEarnGroupsMembers = () => async (dispatch) => {
  dispatch({ type: GET_EARN_GROUPS_MEMBERS_LOADING });
  try {
    const { data, error } = await members.getMembers({ limit: 500 });

    if (data) {
      dispatch({ type: GET_EARN_GROUPS_MEMBERS_SUCCESS, payload: data });
    } else {
      dispatch({
        type: GET_EARN_GROUPS_MEMBERS_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_EARN_GROUPS_MEMBERS_FAIL,
      payload: error,
    });
  }
};

export const updateGroupMemberStatus =
  ({ groupId, memberId, status }) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_EARN_GROUPS_MEMBERS_STATUS_LOADING,
      payload: { groupId, memberId },
    });
    try {
      const { data, error } = await groups.updateGroupMemberById({
        groupId,
        memberId,
        status,
      });

      if (data) {
        dispatch({
          type: UPDATE_EARN_GROUPS_MEMBERS_STATUS_SUCCESS,
          payload: { groupId, memberId, status: data.status },
        });
      } else {
        dispatch({
          type: UPDATE_EARN_GROUPS_MEMBERS_STATUS_FAIL,
          payload: { groupId, memberId, error },
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_EARN_GROUPS_MEMBERS_STATUS_FAIL,
        payload: { groupId, memberId, error },
      });
    }
  };

export const getEarnGroupsMemberById = (memberId) => async (dispatch) => {
  dispatch({ type: GET_EARN_GROUPS_MEMBER_BY_ID_LOADING });
  try {
    const { data, error } = await members.getMemberById({ memberId });

    if (data) {
      dispatch({ type: GET_EARN_GROUPS_MEMBER_BY_ID_SUCCESS, payload: data });
    } else {
      dispatch({
        type: GET_EARN_GROUPS_MEMBER_BY_ID_FAIL,
        payload: error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_EARN_GROUPS_MEMBER_BY_ID_FAIL,
      payload: error,
    });
  }
};

export const getEarnGroupMemberTransactions =
  (memberId) => async (dispatch) => {
    dispatch({ type: GET_EARN_GROUP_MEMBER_TRANSACTIONS_LOADING });
    try {
      const { data, error } = await transactions.getEarngroupMemberTransactions(
        {
          memberId,
        },
      );

      if (data) {
        dispatch({
          type: GET_EARN_GROUP_MEMBER_TRANSACTIONS_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: GET_EARN_GROUP_MEMBER_TRANSACTIONS_FAIL,
          payload: error,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_EARN_GROUP_MEMBER_TRANSACTIONS_FAIL,
        payload: error,
      });
    }
  };
