// User action types
export const SET_USER = "SET_USER";
export const LOADING_USER = "LOADING_USER";

export const GET_USER_TC_BALANCE_LOADING = "GET_USER_TC_BALANCE_LOADING";
export const GET_USER_TC_BALANCE_SUCCESS = "GET_USER_TC_BALANCE_SUCCESS";
export const GET_USER_TC_BALANCE_FAIL = "GET_USER_TC_BALANCE_FAIL";

export const GET_USER_TRANSACTIONS_LOADING = "GET_USER_TRANSACTIONS_LOADING";
export const GET_USER_TRANSACTIONS_SUCCESS = "GET_USER_TRANSACTIONS_SUCCESS";
export const GET_USER_TRANSACTIONS_FAIL = "GET_USER_TRANSACTIONS_FAIL";

// UI action types
export const SET_ERRORS = "SET_ERRORS";
export const LOADING = "LOADING";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const CHANGE_COLOR = "CHANGE_COLOR";
export const IS_MOBILE = "IS_MOBILE";

// AUTH actions
export const GET_LOGGED_IN_USER_INFO_REQUEST =
  "GET_LOGGED_IN_USER_INFO_REQUEST";
export const GET_LOGGED_IN_USER_INFO_SUCESSS =
  "GET_LOGGED_IN_USER_INFO_SUCESSS";
export const GET_LOGGED_IN_USER_INFO_FAIL = "GET_LOGGED_IN_USER_INFO_FAIL";

// LOGIN
export const LOGIN_SUCESSS = "LOGIN_SUCESSS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_LOADING_START = "LOGIN_LOADING_START";
export const LOGIN_LOADING_END = "LOGIN_LOADING_END";

// TEMP LOGIN
export const TEMP_LOGIN_SUCESSS = "TEMP_LOGIN_SUCESSS";
export const TEMP_LOGIN_FAIL = "TEMP_LOGIN_FAIL";
export const TEMP_LOGIN_LOADING_START = "TEMP_LOGIN_LOADING_START";
export const TEMP_LOGIN_LOADING_END = "TEMP_LOGIN_LOADING_END";

// LOGOUT
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

// SESSION REFRESH
export const SESSION_REFRESH_SUCCESS = "SESSION_REFRESH_SUCCESS";
export const SESSION_REFRESH_FAIL = "SESSION_REFRESH_FAIL";

// Data reducer types
export const GET_SPEND_VENUES_SUCCESS = "GET_SPEND_VENUES_SUCCESS";
export const GET_SPEND_VENUES_FAIL = "GET_SPEND_VENUES_FAIL";
export const GET_SPEND_VENUES_LOADING_START = "GET_SPEND_VENUES_LOADING_START";
export const GET_SPEND_VENUES_LOADING_END = "GET_SPEND_VENUES_LOADING_END";

// Activities actions
export const GET_SPEND_ACTIVITIES_SUCCESS = "GET_SPEND_ACTIVITIES_SUCCESS";
export const GET_SPEND_ACTIVITIES_FAIL = "GET_SPEND_ACTIVITIES_FAIL";
export const GET_SPEND_ACTIVITIES_LOADING_START =
  "GET_SPEND_ACTIVITIES_LOADING_START";
export const GET_SPEND_ACTIVITIES_LOADING_END =
  "GET_SPEND_ACTIVITIES_LOADING_END";

// Activities cities & counties actions
export const GET_ACTIVITIES_CITES_COUNTIES_SUCCESS =
  "GET_ACTIVITIES_CITES_COUNTIES_SUCCESS";
export const GET_ACTIVITIES_CITES_COUNTIES_FAIL =
  "GET_ACTIVITIES_CITES_COUNTIES_FAIL";
export const GET_ACTIVITIES_CITES_COUNTIES_LOADING_START =
  "GET_ACTIVITIES_CITES_COUNTIES_LOADING_START";
export const GET_ACTIVITIES_CITES_COUNTIES_LOADING_END =
  "GET_ACTIVITIES_CITES_COUNTIES_LOADING_END";

// ACCEPT TC PAGE
export const GET_SPEND_ACTIVITIES_TO_ACCEPT_SUCCESS =
  "GET_SPEND_ACTIVITIES_TO_ACCEPT_SUCCESS";
export const GET_SPEND_ACTIVITIES_TO_ACCEPT_FAIL =
  "GET_SPEND_ACTIVITIES_TO_ACCEPT_FAIL";
export const GET_SPEND_ACTIVITIES_TO_ACCEPT_LOADING_START =
  "GET_SPEND_ACTIVITIES_TO_ACCEPT_LOADING_START";
export const GET_SPEND_ACTIVITIES_TO_ACCEPT_LOADING_END =
  "GET_SPEND_ACTIVITIES_TO_ACCEPT_LOADING_END";
export const ACCEPT_TC_LOADING_START = "ACCEPT_TC_LOADING_START";
export const ACCEPT_TC_LOADING_END = "ACCEPT_TC_LOADING_END";
export const ACCEPT_TC_SUCCESS = "ACCEPT_TC_SUCCESS";
export const ACCEPT_TC_FAIL = "ACCEPT_TC_FAIL";
export const RESET_ACCEPT_TC_POST_ERROR = "RESET_ACCEPT_TC_POST_ERROR";

// GIVE CREDITS PAGE
export const GET_MEMBERS_SUCCESS = "GET_MEMBERS_SUCCESS";
export const GET_MEMBERS_FAIL = "GET_MEMBERS_FAIL";
export const GET_MEMBERS_LOADING_START = "GET_MEMBERS_LOADING_START";
export const GET_MEMBERS_LOADING_END = "GET_MEMBERS_LOADING_END";

export const GIVE_MEMBER_LOADING_START = "GIVE_MEMBER_LOADING_START";
export const GIVE_MEMBER_LOADING_END = "GIVE_MEMBER_LOADING_END";
export const GIVE_MEMBER_LOADING_SUCCESS = "GIVE_MEMBER_LOADING_SUCCESS";
export const GIVE_MEMBER_LOADING_FAIL = "GIVE_MEMBER_LOADING_FAIL";

export const GIVE_BULK_MEMBER_LOADING_START = "GIVE_BULK_MEMBER_LOADING_START";
export const GIVE_BULK_MEMBER_LOADING_END = "GIVE_BULK_MEMBER_LOADING_END";

export const RESET_GIVE_TC = "RESET_GIVE_TC";

// EarnGroup Dashboard
export const GET_INSIGHTS_SUCCESS = "GET_INSIGHTS_SUCCESS";
export const GET_INSIGHTS_LOADING = "GET_INSIGHTS_LOADING";
export const GET_INSIGHTS_FAIL = "GET_INSIGHTS_FAIL";

export const GET_EARN_GROUPS_MEMBERS_SUCCESS =
  "GET_EARN_GROUPS_MEMBERS_SUCCESS";
export const GET_EARN_GROUPS_MEMBERS_LOADING =
  "GET_EARN_GROUPS_MEMBERS_LOADING";
export const GET_EARN_GROUPS_MEMBERS_FAIL = "GET_EARN_GROUPS_MEMBERS_FAIL";

export const GET_EARN_GROUPS_MEMBER_BY_ID_SUCCESS =
  "GET_EARN_GROUPS_MEMBER_BY_ID_SUCCESS";
export const GET_EARN_GROUPS_MEMBER_BY_ID_LOADING =
  "GET_EARN_GROUPS_MEMBER_BY_ID_LOADING";
export const GET_EARN_GROUPS_MEMBER_BY_ID_FAIL =
  "GET_EARN_GROUPS_MEMBER_BY_ID_FAIL";

export const UPDATE_EARN_GROUPS_MEMBERS_STATUS_SUCCESS =
  "UPDATE_EARN_GROUPS_MEMBERS_STATUS_SUCCESS";
export const UPDATE_EARN_GROUPS_MEMBERS_STATUS_LOADING =
  "UPDATE_EARN_GROUPS_MEMBERS_STATUS_LOADING";
export const UPDATE_EARN_GROUPS_MEMBERS_STATUS_FAIL =
  "UPDATE_EARN_GROUPS_MEMBERS_STATUS_FAIL";

export const GET_EARN_GROUP_MEMBER_TRANSACTIONS_SUCCESS =
  "GET_EARN_GROUP_MEMBER_TRANSACTIONS_SUCCESS";
export const GET_EARN_GROUP_MEMBER_TRANSACTIONS_LOADING =
  "GET_EARN_GROUP_MEMBER_TRANSACTIONS_LOADING";
export const GET_EARN_GROUP_MEMBER_TRANSACTIONS_FAIL =
  "GET_EARN_GROUP_MEMBER_TRANSACTIONS_FAIL";

// Earn group by group Id
export const GET_EARN_GROUP_BY_GROUP_ID_LOADING =
  "GET_EARN_GROUP_BY_GROUP_ID_LOADING";
export const GET_EARN_GROUP_BY_GROUP_ID_SUCCESS =
  "GET_EARN_GROUP_BY_GROUP_ID_SUCCESS";
export const GET_EARN_GROUP_BY_GROUP_ID_FAIL =
  "GET_EARN_GROUP_BY_GROUP_ID_FAIL";

// SIGN UP PAGE
export const GET_EARN_GROUP_INFO_BY_TOKEN_SUCCESS =
  "GET_EARN_GROUP_INFO_BY_TOKEN_SUCCESS";
export const GET_EARN_GROUP_INFO_BY_TOKEN_FAIL =
  "GET_EARN_GROUP_INFO_BY_TOKEN_FAIL";
export const SIGN_UP_POST_LOADING_START = "SIGN_UP_POST_LOADING_START";
export const SIGN_UP_POST_LOADING_END = "SIGN_UP_POST_LOADING_END";
export const SIGN_UP_POST_SUCCESS = "SIGN_UP_POST_SUCCESS";
export const SIGN_UP_POST_FAIL = "SIGN_UP_POST_FAIL";
export const RESET_SIGNUP_UP_ERROR = "RESET_SIGNUP_UP_ERROR";

// TEMPO DASHBOARD Top Stats
export const GET_TEMPO_TOP_STATS_LOADING_START =
  "GET_TEMPO_TOP_STATS_LOADING_START";
export const GET_TEMPO_TOP_STATS_LOADING_END =
  "GET_TEMPO_TOP_STATS_LOADING_END";
export const GET_TEMPO_TOP_STATS_SUCCESS = "GET_TEMPO_TOP_STATS_SUCCESS";
export const GET_TEMPO_TOP_STATS_FAIL = "GET_TEMPO_TOP_STATS_FAIL";

// SPEND VENUE INSIGHTS
export const GET_SPEND_VENUE_INSIGHTS_LOADING_START =
  "GET_SPEND_VENUE_INSIGHTS_LOADING_START";
export const GET_SPEND_VENUE_INSIGHTS_LOADING_END =
  "GET_SPEND_VENUE_INSIGHTS_LOADING_END";
export const GET_SPEND_VENUE_INSIGHTS_SUCCESS =
  "GET_SPEND_VENUE_INSIGHTS_SUCCESS";
export const GET_SPEND_VENUE_INSIGHTS_FAIL = "GET_SPEND_VENUE_INSIGHTS_FAIL";

export const GET_SPEND_VENUE_TRANSACTIONS_LOADING_START =
  "GET_SPEND_VENUE_TRANSACTIONS_LOADING_START";
export const GET_SPEND_VENUE_TRANSACTIONS_LOADING_END =
  "GET_SPEND_VENUE_TRANSACTIONS_LOADING_END";
export const GET_SPEND_VENUE_TRANSACTIONS_SUCCESS =
  "GET_SPEND_VENUE_TRANSACTIONS_SUCCESS";
export const GET_SPEND_VENUE_TRANSACTIONS_FAIL =
  "GET_SPEND_VENUE_TRANSACTIONS_FAIL";

// Spend venue by Id
export const GET_SPEND_VENUE_BY_ID_LOADING_START =
  "GET_SPEND_VENUE_BY_ID_LOADING_START";
export const GET_SPEND_VENUE_BY_ID_LOADING_END =
  "GET_SPEND_VENUE_BY_ID_LOADING_END";
export const GET_SPEND_VENUE_BY_ID_SUCCESS = "GET_SPEND_VENUE_BY_ID_SUCCESS";
export const GET_SPEND_VENUE_BY_ID_FAIL = "GET_SPEND_VENUE_BY_ID_FAIL";

export const GET_SPEND_VENUE_BY_ID_BASIC_LOADING_START =
  "GET_SPEND_VENUE_BY_ID_BASIC_LOADING_START";
export const GET_SPEND_VENUE_BY_ID_BASIC_LOADING_END =
  "GET_SPEND_VENUE_BY_ID_BASIC_LOADING_END";
export const GET_SPEND_VENUE_BY_ID_BASIC_SUCCESS =
  "GET_SPEND_VENUE_BY_ID_BASIC_SUCCESS";
export const GET_SPEND_VENUE_BY_ID_BASIC_FAIL =
  "GET_SPEND_VENUE_BY_ID_BASIC_FAIL";

// Activities by spend venue id
export const GET_ACTIVITIES_BY_SPEND_VENUE_ID_LOADING_START =
  "GET_ACTIVITIES_BY_SPEND_VENUE_ID_LOADING_START";
export const GET_ACTIVITIES_BY_SPEND_VENUE_ID_LOADING_END =
  "GET_ACTIVITIES_BY_SPEND_VENUE_ID_LOADING_END";
export const GET_ACTIVITIES_BY_SPEND_VENUE_ID_SUCCESS =
  "GET_ACTIVITIES_BY_SPEND_VENUE_ID_SUCCESS";
export const GET_ACTIVITIES_BY_SPEND_VENUE_ID_FAIL =
  "GET_ACTIVITIES_BY_SPEND_VENUE_ID_FAIL";

// Actvity type spend (single page)
export const GET_SPEND_ACTIVITY_BY_ID_LOADING_START =
  "GET_SPEND_ACTIVITY_BY_ID_LOADING_START";
export const GET_SPEND_ACTIVITY_BY_ID_LOADING_END =
  "GET_SPEND_ACTIVITY_BY_ID_LOADING_END";
export const GET_SPEND_ACTIVITY_BY_ID_SUCCESS =
  "GET_SPEND_ACTIVITY_BY_ID_SUCCESS";
export const GET_SPEND_ACTIVITY_BY_ID_FAIL = "GET_SPEND_ACTIVITY_BY_ID_FAIL";

// INVITE MEMBERS PAGE
export const SIGN_UP_OFFLINE_LOADING_START = "SIGN_UP_OFFLINE_LOADING_START";
export const SIGN_UP_OFFLINE_LOADING_END = "SIGN_UP_OFFLINE_LOADING_END";
export const SIGN_UP_OFFLINE_SUCCESS = "SIGN_UP_OFFLINE_SUCCESS";
export const SIGN_UP_OFFLINE_FAIL = "SIGN_UP_OFFLINE_FAIL";
export const SIGN_UP_OFFLINE_CLOSE_MODAL = "SIGN_UP_OFFLINE_CLOSE_MODAL";

// REQUEST TIME CREDITS PAGE
export const REQUEST_TC_LOADING_START = "REQUEST_TC_LOADING_START";
export const REQUEST_TC_LOADING_END = "REQUEST_TC_LOADING_END";
export const REQUEST_TC_SUCCESS = "REQUEST_TC_SUCCESS";
export const REQUEST_TC_FAIL = "REQUEST_TC_FAIL";

// PAID REQUEST TIME CREDITS PAGE
export const CREATE_PAID_ORDER_LOADING_START =
  "CREATE_PAID_ORDER_LOADING_START";
export const CREATE_PAID_ORDER_LOADING_END = "CREATE_PAID_ORDER_LOADING_END";
export const CREATE_PAID_ORDER_SUCCESS = "CREATE_PAID_ORDER_SUCCESS";
export const CREATE_PAID_ORDER_FAIL = "CREATE_PAID_ORDER_FAIL";

// PAID REQUEST TIME CREDITS PAGE
export const GET_PAID_PACKAGES_LOADING = "GET_PAID_PACKAGES_LOADING";
export const GET_PAID_PACKAGES_SUCCESS = "GET_PAID_PACKAGES_SUCCESS";
export const GET_PAID_PACKAGES_FAIL = "GET_PAID_PACKAGES_FAIL";

// PAID REQUEST TIME CREDITS PAGE
export const GET_ACTIVE_TC_PRICE_LOADING = "GET_ACTIVE_TC_PRICE_LOADING";
export const GET_ACTIVE_TC_PRICE_SUCCESS = "GET_ACTIVE_TC_PRICE_SUCCESS";
export const GET_ACTIVE_TC_PRICE_FAIL = "GET_ACTIVE_TC_PRICE_FAIL";

// GET REQUEST TIME CREDITS PAGE
export const GET_PENDING_REQUESTS_LOADING_START =
  "GET_PENDING_REQUESTS_LOADING_START";
export const GET_PENDING_REQUESTS_LOADING_END =
  "GET_PENDING_REQUESTS_LOADING_END";
export const GET_PENDING_REQUESTS_SUCCESS = "GET_PENDING_REQUESTS_SUCCESS";
export const GET_PENDING_REQUESTS_FAIL = "GET_PENDING_REQUESTS_FAIL";

export const GET_PREV_REQUESTS_LOADING_START =
  "GET_PREV_REQUESTS_LOADING_START";
export const GET_PREV_REQUESTS_LOADING_END = "GET_PREV_REQUESTS_LOADING_END";
export const GET_PREV_REQUESTS_SUCCESS = "GET_PREV_REQUESTS_SUCCESS";
export const GET_PREV_REQUESTS_FAIL = "GET_PREV_REQUESTS_FAIL";
export const SELECT_CREDITS_REQUEST = "SELECT_CREDITS_REQUEST";

export const GET_SINGLE_REQUEST_SUCCESS = "GET_SINGLE_REQUEST_SUCCESS";
export const GET_SINGLE_REQUEST_FAIL = "GET_SINGLE_REQUEST_FAIL";

export const ISSUE_TC_LOADING_START = "ISSUE_TC_LOADING_START";
export const ISSUE_TC_LOADING_END = "ISSUE_TC_LOADING_END";
export const ISSUE_TC_SUCCESS = "ISSUE_TC_SUCCESS";
export const ISSUE_TC_FAIL = "ISSUE_TC_FAIL";

export const GET_MEMBER_INFO_TO_ISSUEE_TC_LOADING_START =
  "GET_MEMBER_INFO_TO_ISSUEE_TC_LOADING_START";
export const GET_MEMBER_INFO_TO_ISSUEE_TC_LOADING_END =
  "GET_MEMBER_INFO_TO_ISSUEE_TC_LOADING_END";
export const GET_MEMBER_INFO_TO_ISSUEE_TC_SUCCESS =
  "GET_MEMBER_INFO_TO_ISSUEE_TC_SUCCESS";
export const GET_MEMBER_INFO_TO_ISSUEE_TC_FAIL =
  "GET_MEMBER_INFO_TO_ISSUEE_TC_FAIL";

export const GET_EARN_GROUP_INFO_TO_ISSUEE_TC_LOADING_START =
  "GET_EARN_GROUP_INFO_TO_ISSUEE_TC_LOADING_START";
export const GET_EARN_GROUP_INFO_TO_ISSUEE_TC_LOADING_END =
  "GET_EARN_GROUP_INFO_TO_ISSUEE_TC_LOADING_END";
export const GET_EARN_GROUP_INFO_TO_ISSUEE_TC_SUCCESS =
  "GET_EARN_GROUP_INFO_TO_ISSUEE_TC_SUCCESS";
export const GET_EARN_GROUP_INFO_TO_ISSUEE_TC_FAIL =
  "GET_EARN_GROUP_INFO_TO_ISSUEE_TC_FAIL";

export const RESET_ISSUE_TC_STATE = "RESET_ISSUE_TC_STATE";

// tempo staff pages
export const GET_STAFF_INSIGHTS_SUCCESS = "GET_STAFF_INSIGHTS_SUCCESS";
export const GET_STAFF_INSIGHTS_LOADING = "GET_STAFF_INSIGHTS_LOADING";
export const GET_STAFF_INSIGHTS_FAIL = "GET_STAFF_INSIGHTS_FAIL";

export const GET_STAFF_TRANSACTIONS_SUCCESS = "GET_STAFF_TRANSACTIONS_SUCCESS";
export const GET_STAFF_TRANSACTIONS_LOADING = "GET_STAFF_TRANSACTIONS_LOADING";
export const GET_STAFF_TRANSACTIONS_FAIL = "GET_STAFF_TRANSACTIONS_FAIL";

export const GET_STAFF_MEMBERS_SUCCESS = "GET_STAFF_MEMBERS_SUCCESS";
export const GET_STAFF_MEMBERS_LOADING = "GET_STAFF_MEMBERS_LOADING";
export const GET_STAFF_MEMBERS_FAIL = "GET_STAFF_MEMBERS_FAIL";

export const GET_STAFF_EARNGROUPS_SUCCESS = "GET_STAFF_EARNGROUPS_SUCCESS";
export const GET_STAFF_EARNGROUPS_LOADING = "GET_STAFF_EARNGROUPS_LOADING";
export const GET_STAFF_EARNGROUPS_FAIL = "GET_STAFF_EARNGROUPS_FAIL";

export const GET_STAFF_SINGLE_SPEND_VENUE_LOADING =
  "GET_STAFF_SINGLE_SPEND_VENUE_LOADING";
export const GET_STAFF_SINGLE_SPEND_VENUE_SUCCESS =
  "GET_STAFF_SINGLE_SPEND_VENUE_SUCCESS";
export const GET_STAFF_SINGLE_SPEND_VENUE_FAIL =
  "GET_STAFF_SINGLE_SPEND_VENUE_FAIL";
export const GET_STAFF_SINGLE_SPEND_PARTNER_LOADING =
  "GET_STAFF_SINGLE_SPEND_PARTNER_LOADING";
export const GET_STAFF_SINGLE_SPEND_PARTNER_SUCCESS =
  "GET_STAFF_SINGLE_SPEND_PARTNER_SUCCESS";
export const GET_STAFF_SINGLE_SPEND_PARTNER_FAIL =
  "GET_STAFF_SINGLE_SPEND_PARTNER_FAIL";

export const GET_STAFF_SPEND_VENUE_ACTIVITIES_LOADING =
  "GET_STAFF_SPEND_VENUE_ACTIVITIES_LOADING";
export const GET_STAFF_SPEND_VENUE_ACTIVITIES_SUCCESS =
  "GET_STAFF_SPEND_VENUE_ACTIVITIES_SUCCESS";
export const GET_STAFF_SPEND_VENUE_ACTIVITIES_FAIL =
  "GET_STAFF_SPEND_VENUE_ACTIVITIES_FAIL";

export const GET_STAFF_SPEND_VENUES_SUCCESS = "GET_STAFF_SPEND_VENUES_SUCCESS";
export const GET_STAFF_SPEND_VENUES_LOADING = "GET_STAFF_SPEND_VENUES_LOADING";
export const GET_STAFF_SPEND_VENUES_FAIL = "GET_STAFF_SPEND_VENUES_FAIL";

export const GET_STAFF_OFFERS_SUCCESS = "GET_STAFF_OFFERS_SUCCESS";
export const GET_STAFF_OFFERS_LOADING = "GET_STAFF_OFFERS_LOADING";
export const GET_STAFF_OFFERS_FAIL = "GET_STAFF_OFFERS_FAIL";

export const GET_PARTNER_SPEND_VENUES_SUCCESS =
  "GET_PARTNER_SPEND_VENUES_SUCCESS";
export const GET_PARTNER_SPEND_VENUES_LOADING =
  "GET_PARTNER_SPEND_VENUES_LOADING";
export const GET_PARTNER_SPEND_VENUES_FAIL = "GET_PARTNER_SPEND_VENUES_FAIL";

export const GET_STAFF_SINGLE_EARNGROUP_SUCCESS =
  "GET_STAFF_SINGLE_EARNGROUP_SUCCESS";
export const GET_STAFF_SINGLE_EARNGROUP_LOADING =
  "GET_STAFF_SINGLE_EARNGROUP_LOADING";
export const GET_STAFF_SINGLE_EARNGROUP_FAIL =
  "GET_STAFF_SINGLE_EARNGROUP_FAIL";

export const RESET_STAFF_SINGLE_EARNGROUP = "RESET_STAFF_SINGLE_EARNGROUP";
export const GET_STAFF_SINGLE_EARNGROUP_MEMBERS_SUCCESS =
  "GET_STAFF_SINGLE_EARNGROUP_MEMBERS_SUCCESS";
export const GET_STAFF_SINGLE_EARNGROUP_MEMBERS_LOADING =
  "GET_STAFF_SINGLE_EARNGROUP_MEMBERS_LOADING";
export const GET_STAFF_SINGLE_EARNGROUP_MEMBERS_FAIL =
  "GET_STAFF_SINGLE_EARNGROUP_MEMBERS_FAIL";

export const GET_STAFF_SINGLE_EARNGROUP_TRANSACTIONS_SUCCESS =
  "GET_STAFF_SINGLE_EARNGROUP_TRANSACTIONS_SUCCESS";
export const GET_STAFF_SINGLE_EARNGROUP_TRANSACTIONS_LOADING =
  "GET_STAFF_SINGLE_EARNGROUP_TRANSACTIONS_LOADING";
export const GET_STAFF_SINGLE_EARNGROUP_TRANSACTIONS_FAIL =
  "GET_STAFF_SINGLE_EARNGROUP_TRANSACTIONS_FAIL";

export const GET_PROGRAMMES_BASIC_ADMIN_SUCCESS =
  "GET_PROGRAMMES_BASIC_ADMIN_SUCCESS";
export const GET_PROGRAMMES_BASIC_ADMIN_LOADING =
  "GET_PROGRAMMES_BASIC_ADMIN_LOADING";
export const GET_PROGRAMMES_BASIC_ADMIN_FAIL =
  "GET_PROGRAMMES_BASIC_ADMIN_FAIL";

export const UPDATE_PROGRAMME_EARN_GROUP_STATUS_SUCCESS =
  "UPDATE_PROGRAMME_EARN_GROUP_STATUS_SUCCESS";
export const UPDATE_PROGRAMME_EARN_GROUP_STATUS_LOADING =
  "UPDATE_PROGRAMME_EARN_GROUP_STATUS_LOADING";
export const UPDATE_PROGRAMME_EARN_GROUP_STATUS_FAIL =
  "UPDATE_PROGRAMME_EARN_GROUP_STATUS_FAIL";

export const GET_STAFF_SINGLE_MEMBER_SUCCESS =
  "GET_STAFF_SINGLE_MEMBER_SUCCESS";
export const GET_STAFF_SINGLE_MEMBER_LOADING =
  "GET_STAFF_SINGLE_MEMBER_LOADING";
export const GET_STAFF_SINGLE_MEMBER_FAIL = "GET_STAFF_SINGLE_MEMBER_FAIL";

export const DELETE_STAFF_SINGLE_MEMBER_SUCCESS =
  "DELETE_STAFF_SINGLE_MEMBER_SUCCESS";
export const DELETE_STAFF_SINGLE_MEMBER_FAIL =
  "DELETE_STAFF_SINGLE_MEMBER_FAIL";

export const GET_STAFF_SINGLE_MEMBER_TRANSACTIONS_SUCCESS =
  "GET_STAFF_SINGLE_MEMBER_TRANSACTIONS_SUCCESS";
export const GET_STAFF_SINGLE_MEMBER_TRANSACTIONS_LOADING =
  "GET_STAFF_SINGLE_MEMBER_TRANSACTIONS_LOADING";
export const GET_STAFF_SINGLE_MEMBER_TRANSACTIONS_FAIL =
  "GET_STAFF_SINGLE_MEMBER_TRANSACTIONS_FAIL";

export const GET_STAFF_SINGLE_PROGRAMME_LOADING =
  "GET_STAFF_SINGLE_PROGRAMME_LOADING";
export const GET_STAFF_SINGLE_PROGRAMME_SUCCESS =
  "GET_STAFF_SINGLE_PROGRAMME_SUCCESS";
export const GET_STAFF_SINGLE_PROGRAMME_FAIL =
  "GET_STAFF_SINGLE_PROGRAMME_FAIL";

export const GET_STAFF_PROGRAMME_EARN_GROUPS_LOADING =
  "GET_STAFF_PROGRAMME_EARN_GROUPS_LOADING";
export const GET_STAFF_PROGRAMME_EARN_GROUPS_SUCCESS =
  "GET_STAFF_PROGRAMME_EARN_GROUPS_SUCCESS";
export const GET_STAFF_PROGRAMME_EARN_GROUPS_FAIL =
  "GET_STAFF_PROGRAMME_EARN_GROUPS_FAIL";

// Single Transaction (single pages)
export const GET_SINGLE_TRANSACTION_LOADING =
  "GET_SINGLE_TRANSACTION_LOADING_START";
export const GET_SINGLE_TRANSACTION_SUCCESS = "GET_SINGLE_TRANSACTION_SUCCESS";
export const GET_SINGLE_TRANSACTION_FAIL = "GET_SINGLE_TRANSACTION_FAIL";

// STAFF - COMMON
export const SET_SELECTED_MEMBER_TO_ISSUE_TC =
  "SET_SELECTED_MEMBER_TO_ISSUE_TC";

// GIFTS
export const GET_GIFTS_SUCCESS = "GET_GIFTS_SUCCESS";
export const GET_GIFTS_FAIL = "GET_GIFTS_FAIL";
export const GET_GIFTS_LOADING_START = "GET_GIFTS_LOADING_START";
export const GET_GIFTS_LOADING_END = "GET_GIFTS_LOADING_END";

export const CREATE_GIFT_SUCCESS = "CREATE_GIFT_SUCCESS";
export const CREATE_GIFT_FAIL = "CREATE_GIFT_FAIL";
export const CREATE_GIFT_LOADING_START = "CREATE_GIFT_LOADING_START";
export const CREATE_GIFT_LOADING_END = "CREATE_GIFT_LOADING_END";

export const GET_EARN_GROUPS_SUCCESS = "GET_EARN_GROUPS_SUCCESS";
export const GET_EARN_GROUPS_FAIL = "GET_EARN_GROUPS_FAIL";
export const GET_EARN_GROUPS_LOADING_START = "GET_EARN_GROUPS_LOADING_START";
export const GET_EARN_GROUPS_LOADING_END = "GET_EARN_GROUPS_LOADING_END";
export const GET_EARN_GROUPS_LOADING = "GET_EARN_GROUPS_LOADING";

export const SET_GIFT_RECIPIENT = "SET_GIFT_RECIPIENT";

export const CANCEL_GIFT_SUCCESS = "CANCEL_GIFT_SUCCESS";
export const CANCEL_GIFT_FAIL = "CANCEL_GIFT_FAIL";
export const CANCEL_GIFT_LOADING_START = "CANCEL_GIFT_LOADING_START";
export const CANCEL_GIFT_LOADING_END = "CANCEL_GIFT_LOADING_END";

export const GET_GIFT_SUCCESS = "GET_GIFT_SUCCESS";
export const GET_GIFT_FAIL = "GET_GIFT_FAIL";
export const GET_GIFT_LOADING_START = "GET_GIFT_LOADING_START";
export const GET_GIFT_LOADING_END = "GET_GIFT_LOADING_END";

export const RESET_GIFT_ERRORS = "RESET_GIFT_ERRORS";

// RESET PASSWORD PAGES
export const RESET_PASSWORD_LINK_SUCCESS = "RESET_PASSWORD_LINK_SUCCESS";
export const RESET_PASSWORD_LINK_FAIL = "RESET_PASSWORD_LINK_FAIL";
export const RESET_PASSWORD_LINK_LOADING_START =
  "RESET_PASSWORD_LINK_LOADING_START";
export const RESET_PASSWORD_LINK_LOADING_END =
  "RESET_PASSWORD_LINK_LOADING_END";

export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_FAIL = "SET_PASSWORD_FAIL";
export const SET_PASSWORD_LOADING_START = "SET_PASSWORD_LOADING_START";
export const SET_PASSWORD_LOADING_END = "SET_PASSWORD_LOADING_END";
export const RESET_FORGET_PASSWORD_STATE = "RESET_FORGET_PASSWORD_STATE";

// Tk Group Request PAGE
export const GET_TK_GROUP_REQUEST_LOADING_START =
  "GET_TK_GROUP_REQUEST_LOADING_START";
export const GET_TK_GROUP_REQUEST_LOADING_END =
  "GET_TK_GROUP_REQUEST_LOADING_END";
export const GET_TK_GROUP_REQUEST_SUCCESS = "GET_TK_GROUP_REQUEST_SUCCESS";
export const GET_TK_GROUP_REQUEST_FAIL = "GET_TK_GROUP_REQUEST_FAIL";

// Tk approve/reject group
export const TK_APPROVE_REJECT_GROUP_START = "TK_APPROVE_REJECT_GROUP_START";
export const TK_APPROVE_REJECT_GROUP_END = "TK_APPROVE_REJECT_GROUP_END";
export const TK_APPROVE_REJECT_GROUP_SUCCESS =
  "TK_APPROVE_REJECT_GROUP_SUCCESS";
export const TK_APPROVE_REJECT_GROUP_FAIL = "TK_APPROVE_REJECT_GROUP_FAIL";

// Tk Group Single Page
export const GET_TK_GROUP_CONFLICT_DATA_LOADING_START =
  "GET_TK_GROUP_CONFLICT_DATA_LOADING_START";
export const GET_TK_GROUP_CONFLICT_DATA_LOADING_END =
  "GET_TK_GROUP_CONFLICT_DATA_LOADING_END";
export const GET_TK_GROUP_CONFLICT_DATA_SUCCESS =
  "GET_TK_GROUP_CONFLICT_DATA_SUCCESS";
export const GET_TK_GROUP_CONFLICT_DATA_FAIL =
  "GET_TK_GROUP_CONFLICT_DATA_FAIL";

// Tk approve as New group
export const TK_APPROVE_AS_NEW_GROUP_START = "TK_APPROVE_AS_NEW_GROUP_START";
export const TK_APPROVE_AS_NEW_GROUP_SUCCESS =
  "TK_APPROVE_AS_NEW_GROUP_SUCCESS";
export const TK_APPROVE_AS_NEW_GROUP_FAIL = "TK_APPROVE_AS_NEW_GROUP_FAIL";

// Tk merge groups
export const TK_MERGE_GROUPS_START = "TK_MERGE_GROUPS_START";
export const TK_MERGE_GROUPS_SUCCESS = "TK_MERGE_GROUPS_SUCCESS";
export const TK_MERGE_GROUPS_FAIL = "TK_MERGE_GROUPS_FAIL";

// Tk Conflicted Programmes
export const GET_TK_CONFLICTED_PROGRAMME_LOADING_START =
  "GET_TK_CONFLICTED_PROGRAMME_LOADING_STAR";
export const GET_TK_CONFLICTED_PROGRAMME_LOADING_END =
  "GET_TK_CONFLICTED_PROGRAMME_LOADING_END";
export const GET_TK_CONFLICTED_PROGRAMME_SUCCESS =
  "GET_TK_CONFLICTED_PROGRAMME_SUCCESS";
export const GET_CONFLICTED_PROGRAMME_FAIL = "GET_CONFLICTED_PROGRAMME_FAIL";

// Assign Programme
export const TK_ASSIGN_PROGRAMME_START = "TK_ASSIGN_PROGRAMME_START";
export const TK_ASSIGN_PROGRAMME_END = "TK_ASSIGN_PROGRAMME_END";
export const TK_ASSIGN_PROGRAMME_SUCCESS = "TK_ASSIGN_PROGRAMME_SUCCESS";
export const TK_ASSIGN_PROGRAMME_FAIL = "TK_ASSIGN_PROGRAMME_FAIL";

// PROGRAMMES PAGES
export const GET_PROGRAMMES_LOADING_START = "GET_PROGRAMMES_LOADING_START";
export const GET_PROGRAMMES_LOADING_END = "GET_PROGRAMMES_LOADING_END";
export const GET_PROGRAMMES_SUCCESS = "GET_PROGRAMMES_SUCCESS";
export const GET_PROGRAMMES_FAIL = "GET_PROGRAMMES_FAIL";
export const GET_FILTERED_PROGRAMMES_SUCCESS =
  "GET_FILTERED_PROGRAMMES_SUCCESS";
// USER ACCOUNT PAGES
export const GET_USER_ACCOUNT_SUCCESS = "GET_USER_ACCOUNT_SUCCESS";
export const GET_USER_ACCOUNT_FAIL = "GET_USER_ACCOUNT_FAIL";
export const GET_USER_ACCOUNT_LOADING = "GET_USER_ACCOUNT_LOADING";
export const UPDATE_USER_ACCOUNT_SUCCESS = "UPDATE_USER_ACCOUNT_SUCCESS";
export const UPDATE_USER_ACCOUNT_FAIL = "UPDATE_USER_ACCOUNT_FAIL";
export const UPDATE_USER_ACCOUNT_LOADING = "UPDATE_USER_ACCOUNT_LOADING";

export const GET_EARN_GROUP_PROFILE_LOADING_START =
  "GET_EARN_GROUP_PROFILE_LOADING_START";
export const GET_EARN_GROUP_PROFILE_LOADING_END =
  "GET_EARN_GROUP_PROFILE_LOADING_END";
export const GET_EARN_GROUP_PROFILE_SUCCESS = "GET_EARN_GROUP_PROFILE_SUCCESS";
export const GET_EARN_GROUP_PROFILE_FAIL = "GET_EARN_GROUP_PROFILE_FAIL";

export const UPDATE_EARN_GROUP_PROFILE_LOADING_START =
  "UPDATE_EARN_GROUP_PROFILE_LOADING_START";
export const UPDATE_EARN_GROUP_PROFILE_LOADING_END =
  "UPDATE_EARN_GROUP_PROFILE_LOADING_END";
export const UPDATE_EARN_GROUP_PROFILE_SUCCESS =
  "UPDATE_EARN_GROUP_PROFILE_SUCCESS";
export const UPDATE_EARN_GROUP_PROFILE_FAIL = "UPDATE_EARN_GROUP_PROFILE_FAIL";

export const RESET_EARN_GROUP_PROFILE = "RESET_EARN_GROUP_PROFILE_FAIL";

// ADMIN - EDIT SPEND ACTIVITY PAGE
export const GET_SPEND_ACTIVITY_ADMIN_LOADING_START =
  "GET_SPEND_ACTIVITY_ADMIN_LOADING_START";
export const GET_SPEND_ACTIVITY_ADMIN_LOADING_END =
  "GET_SPEND_ACTIVITY_ADMIN_LOADING_END";
export const GET_SPEND_ACTIVITY_ADMIN_SUCCESS =
  "GET_SPEND_ACTIVITY_ADMIN_SUCCESS";
export const GET_SPEND_ACTIVITY_ADMIN_FAIL = "GET_SPEND_ACTIVITY_ADMIN_FAIL";

export const UPDATE_SPEND_ACTIVITY_ADMIN_LOADING_START =
  "UPDATE_SPEND_ACTIVITY_ADMIN_LOADING_START";
export const UPDATE_SPEND_ACTIVITY_ADMIN_LOADING_END =
  "UPDATE_SPEND_ACTIVITY_ADMIN_LOADING_END";
export const UPDATE_SPEND_ACTIVITY_ADMIN_SUCCESS =
  "UPDATE_SPEND_ACTIVITY_ADMIN_SUCCESS";
export const UPDATE_SPEND_ACTIVITY_ADMIN_FAIL =
  "UPDATE_SPEND_ACTIVITY_ADMIN_FAIL";

export const DELETE_SPEND_ACTIVITY_LOADING = "DELETE_SPEND_ACTIVITY_LOADING";
export const DELETE_SPEND_ACTIVITY_SUCCESS = "DELETE_SPEND_ACTIVITY_SUCCESS";
export const DELETE_SPEND_ACTIVITY_FAIL = "DELETE_SPEND_ACTIVITY_FAIL";

export const RESET_SPEND_ACTIVITY_ADMIN = "RESET_SPEND_ACTIVITY_ADMIN";

// MEMBER PURCHASE BOOKING CODES
export const PURCHASE_BOOKING_CODES_LOADING = "PURCHASE_BOOKING_CODES_LOADING";
export const PURCHASE_BOOKING_CODES_SUCCESS = "PURCHASE_BOOKING_CODES_SUCCESS";
export const PURCHASE_BOOKING_CODES_FAIL = "PURCHASE_BOOKING_CODES_FAIL";
export const RESET_PURCHASE_BOOKING_CODES = "RESET_PURCHASE_BOOKING_CODES";

export const GET_USER_BOOKING_CODES_LOADING = "GET_USER_BOOKING_CODES_LOADING";
export const GET_USER_BOOKING_CODES_SUCCESS = "GET_USER_BOOKING_CODES_SUCCESS";
export const GET_USER_BOOKING_CODES_FAIL = "GET_USER_BOOKING_CODES_FAIL";

export const APPROVE_ACTIVITY_APPROVAL_REQUEST_LOADING =
  "APPROVE_ACTIVITY_APPROVAL_REQUEST_LOADING";
export const APPROVE_ACTIVITY_APPROVAL_REQUEST_SUCCESS =
  "APPROVE_ACTIVITY_APPROVAL_REQUEST_SUCCESS";
export const APPROVE_ACTIVITY_APPROVAL_REQUEST_FAIL =
  "APPROVE_ACTIVITY_APPROVAL_REQUEST_FAIL";

export const GET_SPEND_VENUE_PROFILE_ADMIN_LOADING_START =
  "GET_SPEND_VENUE_PROFILE_ADMIN_LOADING_START";
export const GET_SPEND_VENUE_PROFILE_ADMIN_LOADING_END =
  "GET_SPEND_VENUE_PROFILE_ADMIN_LOADING_END";
export const GET_SPEND_VENUE_PROFILE_ADMIN_SUCCESS =
  "GET_SPEND_VENUE_PROFILE_ADMIN_SUCCESS";
export const GET_SPEND_VENUE_PROFILE_ADMIN_FAIL =
  "GET_SPEND_VENUE_PROFILE_ADMIN_FAIL";

export const GET_SPEND_PARTNERS_BASIC_ADMIN_LOADING_START =
  "GET_SPEND_PARTNERS_BASIC_ADMIN_LOADING_START";
export const GET_SPEND_PARTNERS_BASIC_ADMIN_LOADING_END =
  "GET_SPEND_PARTNERS_BASIC_ADMIN_LOADING_END";
export const GET_SPEND_PARTNERS_BASIC_ADMIN_SUCCESS =
  "GET_SPEND_PARTNERS_BASIC_ADMIN_SUCCESS";
export const GET_SPEND_PARTNERS_BASIC_ADMIN_FAIL =
  "GET_SPEND_PARTNERS_BASIC_ADMIN_FAIL";

export const UPDATE_SPEND_VENUE_PROFILE_ADMIN_LOADING_START =
  "UPDATE_SPEND_VENUE_PROFILE_ADMIN_LOADING_START";
export const UPDATE_SPEND_VENUE_PROFILE_ADMIN_LOADING_END =
  "UPDATE_SPEND_VENUE_PROFILE_ADMIN_LOADING_END";
export const UPDATE_SPEND_VENUE_PROFILE_ADMIN_SUCCESS =
  "UPDATE_SPEND_VENUE_PROFILE_ADMIN_SUCCESS";
export const UPDATE_SPEND_VENUE_PROFILE_ADMIN_FAIL =
  "UPDATE_SPEND_VENUE_PROFILE_ADMIN_FAIL";

export const RESET_SPEND_VENUE_PROFILE_ADMIN =
  "RESET_SPEND_VENUE_PROFILE_ADMIN";

export const GET_PROGRAMME_INVITE_DETAILS_LOADING =
  "GET_PROGRAMME_INVITE_DETAILS_LOADING";
export const GET_PROGRAMME_INVITE_DETAILS_SUCCESS =
  "GET_PROGRAMME_INVITE_DETAILS_SUCCESS";
export const GET_PROGRAMME_INVITE_DETAILS_FAIL =
  "GET_PROGRAMME_INVITE_DETAILS_FAIL";

export const UPDATE_MEMBER_PROFILE_LOADING_START =
  "UPDATE_MEMBER_PROFILE_LOADING_START";
export const UPDATE_MEMBER_PROFILE_LOADING_END =
  "UPDATE_MEMBER_PROFILE_LOADING_END";
export const UPDATE_MEMBER_PROFILE_SUCCESS = "UPDATE_MEMBER_PROFILE_SUCCESS";
export const UPDATE_MEMBER_PROFILE_FAIL = "UPDATE_MEMBER_PROFILE_FAIL";

export const GET_STAFF_USERS_LOADING_START = "GET_STAFF_USERS_LOADING_START";
export const GET_STAFF_USERS_LOADING_END = "GET_STAFF_USERS_LOADING_END";
export const GET_STAFF_USERS_SUCCESS = "GET_STAFF_USERS_SUCCESS";
export const GET_STAFF_USERS_FAIL = "GET_STAFF_USERS_FAIL";

export const ADD_STAFF_USER_LOADING_START = "ADD_STAFF_USER_LOADING_START";
export const ADD_STAFF_USER_LOADING_END = "ADD_STAFF_USER_LOADING_END";
export const ADD_STAFF_USER_SUCCESS = "ADD_STAFF_USER_SUCCESS";
export const ADD_STAFF_USER_FAIL = "ADD_STAFF_USER_FAIL";

export const DEACTIVATE_STAFF_USER_LOADING_START =
  "DEACTIVATE_STAFF_USER_LOADING_START";
export const DEACTIVATE_STAFF_USER_LOADING_END =
  "DEACTIVATE_STAFF_USER_LOADING_END";
export const DEACTIVATE_STAFF_USER_SUCCESS = "DEACTIVATE_STAFF_USER_SUCCESS";
export const DEACTIVATE_STAFF_USER_FAIL = "DEACTIVATE_STAFF_USER_FAIL";

export const UPDATE_STAFF_USER_LOADING_START =
  "UPDATE_STAFF_USER_LOADING_START";
export const UPDATE_STAFF_USER_LOADING_END = "UPDATE_STAFF_USER_LOADING_END";
export const UPDATE_STAFF_USER_SUCCESS = "UPDATE_STAFF_USER_SUCCESS";
export const UPDATE_STAFF_USER_FAIL = "UPDATE_STAFF_USER_FAIL";

export const GET_EARN_GROUPS_ADMINS_LOADING_START =
  "GET_EARN_GROUPS_ADMINS_LOADING_START";
export const GET_EARN_GROUPS_ADMINS_LOADING_END =
  "GET_EARN_GROUPS_ADMINS_LOADING_END";
export const GET_EARN_GROUPS_ADMINS_SUCCESS = "GET_EARN_GROUPS_ADMINS_SUCCESS";
export const GET_EARN_GROUPS_ADMINS_FAIL = "GET_EARN_GROUPS_ADMINS_FAIL";

export const ADD_EARN_GROUP_ADMINISTRATOR_LOADING_START =
  "ADD_EARN_GROUP_ADMINISTRATOR_LOADING_START";
export const ADD_EARN_GROUP_ADMINISTRATOR_LOADING_END =
  "ADD_EARN_GROUP_ADMINISTRATOR_LOADING_END";
export const ADD_EARN_GROUP_ADMINISTRATOR_SUCCESS =
  "ADD_EARN_GROUP_ADMINISTRATOR_SUCCESS";
export const ADD_EARN_GROUP_ADMINISTRATOR_FAIL =
  "ADD_EARN_GROUP_ADMINISTRATOR_FAIL";

export const ADD_MEMBERS_TO_EARN_GROUP_LOADING =
  "ADD_MEMBERS_TO_EARN_GROUP_LOADING";
export const ADD_MEMBERS_TO_EARN_GROUP_SUCCESS =
  "ADD_MEMBERS_TO_EARN_GROUP_SUCCESS";
export const ADD_MEMBERS_TO_EARN_GROUP_FAIL = "ADD_MEMBERS_TO_EARN_GROUP_FAIL ";

export const DELETE_ADMINISTRATOR_USER_LOADING_START =
  "DELETE_ADMINISTRATOR_USER_LOADING_START";
export const DELETE_ADMINISTRATOR_USER_LOADING_END =
  "DELETE_ADMINISTRATOR_USER_LOADING_END";
export const DELETE_ADMINISTRATOR_USER_SUCCESS =
  "DELETE_ADMINISTRATOR_USER_SUCCESS";
export const DELETE_ADMINISTRATOR_USER_FAIL = "DELETE_ADMINISTRATOR_USER_FAIL";

export const GET_PROGRAMME_MANAGERS_LOADING_START =
  "GET_PROGRAMME_MANAGERS_LOADING_START";
export const GET_PROGRAMME_MANAGERS_LOADING_END =
  "GET_PROGRAMME_MANAGERS_LOADING_END";
export const GET_PROGRAMME_MANAGERS_SUCCESS = "GET_PROGRAMME_MANAGERS_SUCCESS";
export const GET_PROGRAMME_MANAGERS_FAIL = "GET_PROGRAMME_MANAGERS_FAIL";

export const ADD_PROGRAMME_MANAGER_LOADING_START =
  "ADD_PROGRAMME_MANAGER_LOADING_START";
export const ADD_PROGRAMME_MANAGER_LOADING_END =
  "ADD_PROGRAMME_MANAGER_LOADING_END";
export const ADD_PROGRAMME_MANAGER_SUCCESS = "ADD_PROGRAMME_MANAGER_SUCCESS";
export const ADD_PROGRAMME_MANAGER_FAIL = "ADD_PROGRAMME_MANAGER_FAIL";

export const DELETE_PROGRAMME_MANAGER_LOADING_START =
  "DELETE_PROGRAMME_MANAGER_LOADING_START";
export const DELETE_PROGRAMME_MANAGER_LOADING_END =
  "DELETE_PROGRAMME_MANAGER_LOADING_END";
export const DELETE_PROGRAMME_MANAGER_SUCCESS =
  "DELETE_PROGRAMME_MANAGER_SUCCESS";
export const DELETE_PROGRAMME_MANAGER_FAIL = "DELETE_PROGRAMME_MANAGER_FAIL";

// get location types
export const GET_LOCATION_LOADING_START = "GET_LOCATION_LOADING_START";
export const GET_LOCATION_SUCCESS = "GET_LOCATION_SUCCESS";
export const GET_LOCATION_FAIL = "GET_LOCATION_FAIL";
export const GET_LOCATION_LOADING_END = "GET_LOCATION_LOADING_END";

// update user language
export const UPDATE_LANGUAGE_LOADING_START = "UPDATE_LANGUAGE_LOADING_START";
export const UPDATE_LANGUAGE_SUCCESS = "UPDATE_LANGUAGE_SUCCESS";
export const UPDATE_LANGUAGE_FAIL = "UPDATE_LANGUAGE_FAIL";

// delete EG
export const DELETE_EARNGROUP_LOADING_START = "DELETE_EARNGROUP_LOADING_START";
export const DELETE_EARNGROUP_LOADING_END = "DELETE_EARNGROUP_LOADING_END";
export const DELETE_EARNGROUP_SUCCESS = "DELETE_EARNGROUP_LOADING";
export const DELETE_EARNGROUP_FAIL = "DELETE_EARNGROUP_LOADING";

export const CREATE_SPEND_PARTNER_LOADING_START =
  "CREATE_SPEND_PARTNER_LOADING_START";
export const CREATE_SPEND_PARTNER_LOADING_END =
  "CREATE_SPEND_PARTNER_LOADING_END";
export const CREATE_SPEND_PARTNER_SUCCESS = "CREATE_SPEND_PARTNER_SUCCESS";
export const CREATE_SPEND_PARTNER_FAIL = "CREATE_SPEND_PARTNER_FAIL";

export const GET_SPEND_PARTNER_LOADING_START =
  "GET_SPEND_PARTNER_LOADING_START";
export const GET_SPEND_PARTNER_LOADING_END = "GET_SPEND_PARTNER_LOADING_END";
export const GET_SPEND_PARTNER_SUCCESS = "GET_SPEND_PARTNER_SUCCESS";
export const GET_SPEND_PARTNER_FAIL = "GET_SPEND_PARTNER_FAIL";

export const CREATE_SPEND_VENUE_CRED_LOADING_START =
  "CREATE_SPEND_VENUE_CRED_LOADING_START";
export const CREATE_SPEND_VENUE_CRED_LOADING_END =
  "CREATE_SPEND_VENUE_CRED_LOADING_END";
export const CREATE_SPEND_VENUE_CRED_SUCCESS =
  "CREATE_SPEND_VENUE_CRED_SUCCESS";
export const CREATE_SPEND_VENUE_CRED_FAIL = "CREATE_SPEND_VENUE_CRED_FAIL";

export const UPDATE_ACCEPT_TERMS_LOADING = "UPDATE_ACCEPT_TERMS_LOADING";
export const UPDATE_ACCEPT_TERMS_SUCCESS = "UPDATE_ACCEPT_TERMS_SUCCESS";
export const UPDATE_ACCEPT_TERMS_FAIL = "UPDATE_ACCEPT_TERMS_FAIL";

export const GET_SELECT_USER_LOADING = "GET_SELECT_USER_LOADING";
export const GET_SELECT_USER_SUCCESS = "GET_SELECT_USER_SUCCESS";
export const GET_SELECT_USER_FAIL = "GET_SELECT_USER_FAIL";
export const SELECT_ACTIVE_USER_LOADING = "SELECT_ACTIVE_USER_LOADING";
export const SELECT_ACTIVE_USER_FAIL = "SELECT_ACTIVE_USER_FAIL";
// SPEND VENUE OFFERS
export const GET_SPEND_VENUE_OFFERS_LOADING = "GET_SPEND_VENUE_OFFERS_LOADING";
export const GET_SPEND_VENUE_OFFERS_SUCCESS = "GET_SPEND_VENUE_OFFERS_SUCCESS";
export const GET_SPEND_VENUE_OFFERS_FAIL = "GET_SPEND_VENUE_OFFERS_FAIL";

// EARN GROUP OFFERS
export const GET_EARN_GROUP_OFFERS_LOADING = "GET_EARN_GROUP_OFFERS_LOADING";
export const GET_EARN_GROUP_OFFERS_SUCCESS = "GET_EARN_GROUP_OFFERS_SUCCESS";
export const GET_EARN_GROUP_OFFERS_FAIL = "GET_EARN_GROUP_OFFERS_FAIL";

export const UPDATE_SELECTED_VENUE_LOADING = "UPDATE_SELECTED_VENUE_LOADING";
export const UPDATE_SELECTED_VENUE_SUCCESS = "UPDATE_SELECTED_VENUE_SUCCESS";
export const UPDATE_SELECTED_VENUE_FAIL = "UPDATE_SELECTED_VENUE_FAIL";

export const UPDATE_SV_PARTNER_LOADING = "UPDATE_SV_PARTNER_LOADING";
export const UPDATE_SV_PARTNER_SUCCESS = "UPDATE_SV_PARTNER_SUCCESS";
export const UPDATE_SV_PARTNER_FAIL = "UPDATE_SV_PARTNER_FAIL";

export const CREATE_TICKET_LOADING_START = "CREATE_TICKET_LOADING_START";
export const CREATE_TICKET_SUCCESS = "CREATE_TICKET_SUCCESS";
export const CREATE_TICKET_FAIL = "CREATE_TICKET_FAIL";
export const CREATE_TICKET_LOADING_END = "CREATE_TICKET_LOADING_END";

export const GET_ACTIVE_APPROVAL_REQUESTS_LOADING =
  "GET_ACTIVE_APPROVAL_REQUESTS_LOADING";
export const GET_ACTIVE_APPROVAL_REQUESTS_SUCCESS =
  "GET_ACTIVE_APPROVAL_REQUESTS_SUCCESS";
export const GET_ACTIVE_APPROVAL_REQUESTS_FAIL =
  "GET_ACTIVE_APPROVAL_REQUESTS_FAIL";

export const GET_PREV_APPROVAL_REQUESTS_LOADING =
  "GET_PREV_APPROVAL_REQUESTS_LOADING";
export const GET_PREV_APPROVAL_REQUESTS_SUCCESS =
  "GET_PREV_APPROVAL_REQUESTS_SUCCESS";
export const GET_PREV_APPROVAL_REQUESTS_FAIL =
  "GET_PREV_APPROVAL_REQUESTS_FAIL";

export const GET_EARN_GROUP_TRANSACTIONS_LOADING_START =
  "GET_EARN_GROUP_TRANSACTIONS_LOADING_START";
export const GET_EARN_GROUP_TRANSACTIONS_SUCCESS =
  "GET_EARN_GROUP_TRANSACTIONS_SUCCESS";
export const GET_EARN_GROUP_TRANSACTIONS_FAIL =
  "GET_EARN_GROUP_TRANSACTIONS_FAIL";
export const GET_MY_GROUPS_FAIL = "GET_MY_GROUPS_FAIL";
export const GET_MY_GROUPS_LOADING = "GET_MY_GROUPS_LOADING";
export const GET_MY_GROUPS_SUCCESS = "GET_MY_GROUPS_SUCCESS";

export const CHANGE_MAIN_ACCOUNT_LOADING = "CHANGE_MAIN_ACCOUNT_LOADING";
export const CHANGE_MAIN_ACCOUNT_SUCCESS = "CHANGE_MAIN_ACCOUNT_SUCCESS";
export const CHANGE_MAIN_ACCOUNT_FAIL = "CHANGE_MAIN_ACCOUNT_FAIL";

export const EARN_GROUP_ACCOUNT_HOLDER_LOGS_LOADING =
  "EARN_GROUP_ACCOUNT_HOLDER_LOGS_LOADING";
export const EARN_GROUP_ACCOUNT_HOLDER_LOGS_SUCCESS =
  "EARN_GROUP_ACCOUNT_HOLDER_LOGS_SUCCESS";
export const EARN_GROUP_ACCOUNT_HOLDER_LOGS_FAIL =
  "EARN_GROUP_ACCOUNT_HOLDER_LOGS_FAIL";

export const UPDATE_PACKAGES_SUCCESS = "UPDATE_PACKAGES_SUCCESS";
export const UPDATE_PACKAGES_LOADING = "UPDATE_PACKAGES_LOADING";
export const UPDATE_PACKAGES_FAIL = "UPDATE_PACKAGES_FAIL";

export const GET_ORDER_INFO_LOADING = "GET_ORDER_INFO_LOADING";
export const GET_ORDER_INFO_SUCCESS = "GET_ORDER_INFO_SUCCESS";
export const GET_ORDER_INFO_FAIL = "GET_ORDER_INFO_FAIL";

export const CONFIRM_ORDER_INFO_LOADING = "CONFIRM_ORDER_INFO_LOADING";
export const CONFIRM_ORDER_INFO_SUCCESS = "CONFIRM_ORDER_INFO_SUCCESS";
export const CONFIRM_ORDER_INFO_FAIL = "CONFIRM_ORDER_INFO_FAIL";

// orders
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_LOADING = "GET_ORDERS_LOADING";
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL";

export const CONFIRM_ORDER_CANCELED_LOADING = "CONFIRM_ORDER_CANCELED_LOADING";
export const CONFIRM_ORDER_CANCELED_SUCCESS = "CONFIRM_ORDER_CANCELED_SUCCESS";
export const CONFIRM_ORDER_CANCELED_FAIL = "CONFIRM_ORDER_CANCELED_FAIL";

// order invoice
export const GET_ORDER_INVOICE_LOADING = "GET_ORDER_INVOICE_LOADING";
export const GET_ORDER_INVOICE_SUCCESS = "GET_ORDER_INVOICE_SUCCESS";
export const GET_ORDER_INVOICE_FAIL = "GET_ORDER_INVOICE_FAIL";

export const CHECK_ACTIVATE_ACCOUNT_TOKEN_LOADING =
  "CHECK_ACTIVATE_ACCOUNT_TOKEN_LOADING";
export const CHECK_ACTIVATE_ACCOUNT_TOKEN_SUCCESS =
  "CHECK_ACTIVATE_ACCOUNT_TOKEN_SUCCESS";
export const CHECK_ACTIVATE_ACCOUNT_TOKEN_FAIL =
  "CHECK_ACTIVATE_ACCOUNT_TOKEN_FAIL";

export const GET_ACTIVATE_ACCOUNT_TOKEN_LOADING =
  "GET_ACTIVATE_ACCOUNT_TOKEN_LOADING";
export const GET_ACTIVATE_ACCOUNT_TOKEN_SUCCESS =
  "GET_ACTIVATE_ACCOUNT_TOKEN_SUCCESS";
export const GET_ACTIVATE_ACCOUNT_TOKEN_FAIL =
  "GET_ACTIVATE_ACCOUNT_TOKEN_FAIL";

export const RESEND_ACTIVATE_ACCOUNT_TOKEN_LOADING =
  "RESEND_ACTIVATE_ACCOUNT_TOKEN_LOADING";
export const RESEND_ACTIVATE_ACCOUNT_TOKEN_SUCCESS =
  "RESEND_ACTIVATE_ACCOUNT_TOKEN_SUCCESS";
export const RESEND_ACTIVATE_ACCOUNT_TOKEN_FAIL =
  "RESEND_ACTIVATE_ACCOUNT_TOKEN_FAIL";

export const DUPLICATE_SPEND_ACTIVITY_LOADING =
  "DUPLICATE_SPEND_ACTIVITY_LOADING";
export const DUPLICATE_SPEND_ACTIVITY_SUCCESS =
  "DUPLICATE_SPEND_ACTIVITY_SUCCESS";
export const DUPLICATE_SPEND_ACTIVITY_FAIL = "DUPLICATE_SPEND_ACTIVITY_FAIL";
export const RESET_DUPLICATE_SPEND_ACTIVITY_STATE =
  "RESET_DUPLICATE_SPEND_ACTIVITY_STATE";
