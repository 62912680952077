import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTheme } from "@material-ui/core/styles";

// ICONS
import AdminOfficeSupport from "./icons/AdminOfficeSupport";
import AlertCycle from "./icons/AlertCycle";
import AlertTriangle from "./icons/AlertTriangle";
import ArrowDown from "./icons/ArrowDown";
import ArrowUp from "./icons/ArrowUp";
import DevelopingServices from "./icons/DevelopingServices";
import FundraisingPromotion from "./icons/FundraisingPromotion";
import Operations from "./icons/Operations";
import PracticalHelp from "./icons/PracticalHelp";
import ProfessionalSkills from "./icons/ProfessionalSkills";
import RunningActivity from "./icons/RunningActivity";
import SupportingOthers from "./icons/SupportingOthers";
import Tick from "./icons/Tick";
import ChevronDown from "./icons/ChevronDown";
import ChevronLeft from "./icons/ChevronLeft";
import ChevronRight from "./icons/ChevronRight";
import ChevronUp from "./icons/ChevronUp";
import Close from "./icons/Close";
import Copy from "./icons/Copy";
import Export from "./icons/Export";
import Eye from "./icons/Eye";
import Facebook from "./icons/Facebook";
import Info from "./icons/Info";
import Menu from "./icons/Menu";
import No from "./icons/No";
import Phone from "./icons/Phone";
import Plus from "./icons/Plus";
import QuestionMark from "./icons/QuestionMark";
import Search from "./icons/Search";
import TeachingTraining from "./icons/TeachingTraining";
import TickRound from "./icons/TickRound";
import UpArrow from "./icons/upArrow";
import PlusCircle from "./icons/PlusCircle";
import MinusCircle from "./icons/MinusCircle";
import CheckboxChecked from "./icons/CheckboxChecked";
import CheckboxUnchecked from "./icons/CheckboxUnchecked";
import BackArrow from "./icons/BackArrow";
import ArrowLink from "./icons/ArrowLink";
import At from "./icons/At";
import Twitter from "./icons/Twitter";
import Linkedin from "./icons/Linkedin";
import Instagram from "./icons/Instagram";
import Gift from "./icons/Gift";
import CreditCircle from "./icons/CreditCircle";
import CreditCircleGradient from "./icons/CreditCircleGradient";
import DownArrow from "./icons/DownArrow";
import Calendar from "./icons/Calendar";
import Location from "./icons/Location";
import Star from "./icons/Star";
import ArrowWhite from "./icons/ArrowWhite";
import DownloadOutline from "./icons/DownloadOutline";
import ArrowRight from "./icons/ArrowRight";
import Email from "./icons/Email";
import Computer from "./icons/Computer";
import File from "./icons/File";
import Clock from "./icons/Clock";
import SomethingElse from "./icons/SomethingElse";
import Youtube from "./icons/Youtube";
import ChangeArrows from "./icons/ChangeArrows";
import Completed from "./icons/Completed";
import NotComplete from "./icons/NotComplete";
import PaymentCard from "./icons/PaymentCard";
import Translation from "./icons/Translation";
import Edit from "./icons/Edit";

const useStyles = makeStyles({
  root: {
    height: ({ height }) => height || "100%",
    width: ({ width }) => width || "100%",
    margin: ({ margin }) => margin || "0 0 0 0",
  },
});

export const iconMap = {
  adminOfficeSupport: AdminOfficeSupport,
  alertCycle: AlertCycle,
  alertTriangle: AlertTriangle,
  arrowDown: ArrowDown,
  arrowUp: ArrowUp,
  developingServices: DevelopingServices,
  fundraisingPromotion: FundraisingPromotion,
  operations: Operations,
  practicalHelp: PracticalHelp,
  professionalSkills: ProfessionalSkills,
  runningActivity: RunningActivity,
  supportingOthers: SupportingOthers,
  tick: Tick,
  chevronDown: ChevronDown,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronUp: ChevronUp,
  close: Close,
  copy: Copy,
  export: Export,
  eye: Eye,
  facebook: Facebook,
  info: Info,
  location: Location,
  menu: Menu,
  no: No,
  phone: Phone,
  plus: Plus,
  questionMark: QuestionMark,
  search: Search,
  teachingTraining: TeachingTraining,
  tickRound: TickRound,
  upArrow: UpArrow,
  plusCircle: PlusCircle,
  minusCircle: MinusCircle,
  checkboxChecked: CheckboxChecked,
  checkboxUnchecked: CheckboxUnchecked,
  backArrow: BackArrow,
  at: At,
  arrowLink: ArrowLink,
  twitter: Twitter,
  linkedin: Linkedin,
  instagram: Instagram,
  gift: Gift,
  creditCircle: CreditCircle,
  creditCircleGradient: CreditCircleGradient,
  downArrow: DownArrow,
  calendar: Calendar,
  star: Star,
  arrowWhite: ArrowWhite,
  downloadOutline: DownloadOutline,
  arrowRight: ArrowRight,
  email: Email,
  computer: Computer,
  file: File,
  clock: Clock,
  somethingElse: SomethingElse,
  youtube: Youtube,
  changeArrows: ChangeArrows,
  completed: Completed,
  notComplete: NotComplete,
  paymentCard: PaymentCard,
  translation: Translation,
  edit: Edit,
};

const Icon = (props) => {
  // takes icon, color, width, height and customStyle as props
  const theme = useTheme();

  const { icon, custom, color, background } = props;

  // insert styles

  const classes = useStyles(props);

  const IconMap = Object.keys(iconMap).reduce((accum, curr) => {
    const IconSvg = iconMap[curr];

    if (!IconSvg) {
      throw new Error(`Icon ${curr} not found`);
    }
    // eslint-disable-next-line no-param-reassign
    accum[curr] = IconSvg;
    return accum;
  }, {});

  if (!iconMap[icon]) {
    // eslint-disable-next-line no-console
    console.warn(`<Icon /> called with invalid icon prop "${icon}"`);
    return null;
  }

  const StyledIcon = IconMap[icon];

  return (
    <StyledIcon
      {...props}
      className={classes.root}
      style={custom}
      color={theme.colors[color] || color || "currentColor"}
      background={theme.colors[background] || background || "currentColor"}
    />
  );
};

export default Icon;
