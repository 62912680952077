import { styled } from "@material-ui/core/styles";
import { H40Caps } from "../../../components/Typograpy";

export const MemberWrapper = styled("section")({
  display: "flex",
  position: "relative",
  flexDirection: "column",
});

export const Header = styled("header")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  paddingLeft: "1rem",
  width: "100%",

  [theme.breakpoints.tablet]: {
    paddingLeft: "0rem",
  },
}));

export const LinkWrapper = styled("section")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",

  [theme.breakpoints.mobileL]: {
    "& a": {
      marginBottom: theme.spacing(1),
    },
  },

  [theme.breakpoints.mobileXL]: {
    "& a:nth-child(odd)": {
      marginRight: theme.spacing(1),
    },
  },
}));

export const MemberHeader = styled("header")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  paddingTop: theme.spacing(6),
  marginBottom: theme.spacing(5),
  [theme.breakpoints.tablet]: {
    marginBottom: theme.spacing(7),
  },
}));

export const Div = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  flexDirection: "column-reverse",
  alignItems: "center",
  marginTop: 50,
  "@media (min-width: 1229px)": {
    marginTop: 0,
    flexDirection: "row",
  },
});

export const BigLinkWrapper = styled("div")(({ status }) => {
  const display = status === "activated" ? "block" : "none";
  return {
    transform: "translateY(0px)",
    display,
    "@media (min-width: 1229px)": {
      transform: "translateY(-75px)",
      flexDirection: "row",
    },
  };
});

export const MemberDetails = styled("div")(() => ({
  width: "100%",
}));

export const MemberStatus = styled("span")(({ theme }) => ({
  paddingTop: theme.spacing(1),
  display: "flex",
  paddingLeft: theme.spacing(3),
}));

export const StatsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  paddingTop: theme.spacing(8),
  marginBottom: theme.spacing(4),
  justifyContent: "space-between",
  flexDirection: "column",
  alignItems: "center",
  height: 300,
  "@media (min-width: 1280px)": {
    flexDirection: "row",
    justifyContent: "flex-start",
    height: "auto",
  },
}));

export const TableWrapper = styled("section")(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(2),

  [theme.breakpoints.mobileXL]: {
    paddingLeft: "0",
    paddingRight: "0",
  },
}));

export const MemberSectionWrapper = styled("section")(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),

  [theme.breakpoints.mobileS]: {
    paddingTop: "0",
    marginBottom: theme.spacing(6),
  },

  [theme.breakpoints.mobileXL]: {
    paddingTop: theme.spacing(2),
    paddingLeft: "0",
    paddingRight: "0",
  },
}));

export const MemberSectionHeader = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  flexDirection: "column-reverse",
  alignItems: "center",
  marginTop: 50,
  "@media (min-width: 1229px)": {
    flexDirection: "row",
  },
});

export const TableContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
});

export const BtnContainer = styled("div")({
  alignSelf: "flex-end",
  transform: "translateY(60px)",
  zIndex: 3,
});

export const MemberName = styled(H40Caps)(({ theme }) => ({
  color: theme.colors.midnight,
  paddingLeft: "20px",
  width: "100%",
  margin: 0,
  "@media (min-width: 1280px)": {
    width: "50%",
  },
}));
