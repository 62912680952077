import {
  GET_EARN_GROUPS_MEMBERS_SUCCESS,
  GET_EARN_GROUPS_MEMBERS_LOADING,
  GET_EARN_GROUPS_MEMBERS_FAIL,
  UPDATE_EARN_GROUPS_MEMBERS_STATUS_SUCCESS,
  UPDATE_EARN_GROUPS_MEMBERS_STATUS_LOADING,
  UPDATE_EARN_GROUPS_MEMBERS_STATUS_FAIL,
  GET_EARN_GROUPS_MEMBER_BY_ID_SUCCESS,
  GET_EARN_GROUPS_MEMBER_BY_ID_LOADING,
  GET_EARN_GROUPS_MEMBER_BY_ID_FAIL,
  GET_EARN_GROUP_MEMBER_TRANSACTIONS_SUCCESS,
  GET_EARN_GROUP_MEMBER_TRANSACTIONS_LOADING,
  GET_EARN_GROUP_MEMBER_TRANSACTIONS_FAIL,
} from "../../../redux/types";

const initState = {
  loading: false,
  error: null,
  updateLoading: false,
  allMembers: [],
  memberDetails: {},
  memberTransactions: [],
  memberTransactionsLoaded: false,
  loadingMemberId: false,
};

const earnGroupMembersReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    // MEMBERS
    case GET_EARN_GROUPS_MEMBERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_EARN_GROUPS_MEMBERS_SUCCESS:
      return {
        ...state,
        allMembers: payload,
        loading: false,
      };
    case GET_EARN_GROUPS_MEMBERS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    // MEMBER STATUS
    case UPDATE_EARN_GROUPS_MEMBERS_STATUS_LOADING:
      return {
        ...state,
        updateLoading: true,
        loadingMemberId: payload.memberId,
        // member.groupId === payload.groupId &&
        // member.id === payload.memberId
      };
    case UPDATE_EARN_GROUPS_MEMBERS_STATUS_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        loadingMemberId: null,
        // allMembers: state.allMembers.map((member) => {
        //   if (
        //     member.groupId === payload.groupId &&
        //     member.id === payload.memberId
        //   ) {
        //     return { ...member, loading: false, status: payload.status };
        //   }
        //   return member;
        // }),
        allMembers: state.allMembers.map((member) => {
          if (
            member.groupId === payload.groupId &&
            member.id === payload.memberId
          ) {
            return { ...member, loading: false, status: payload.status };
          }
          return member;
        }),
      };
    case UPDATE_EARN_GROUPS_MEMBERS_STATUS_FAIL:
      return {
        ...state,
        updateLoading: false,
        allMembers: state.allMembers.map((member) => {
          if (
            member.groupId === payload.groupId &&
            member.id === payload.memberId
          ) {
            return { ...member, loading: false, error: payload.error };
          }
          return member;
        }),
      };
    // Single Member

    case GET_EARN_GROUPS_MEMBER_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_EARN_GROUPS_MEMBER_BY_ID_SUCCESS:
      return {
        ...state,
        memberDetails: payload,
        loading: false,
      };
    case GET_EARN_GROUPS_MEMBER_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    // Member Transactions
    case GET_EARN_GROUP_MEMBER_TRANSACTIONS_LOADING:
      return {
        ...state,
        loading: true,
        memberTransactionsLoaded: false,
      };
    case GET_EARN_GROUP_MEMBER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        memberTransactions: payload,
        loading: false,
        memberTransactionsLoaded: true,
      };
    case GET_EARN_GROUP_MEMBER_TRANSACTIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default earnGroupMembersReducer;
