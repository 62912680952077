import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import useStyle from "./style";
import Image from "../../Image";
import Footer from "../../Footer";
import ZendeskButton from "../../ZendeskButton";
import Toggle from "../../Toggle";

const Onboarding = ({
  children,
  hideLangToggle,
  ...props
}) => {
  const classes = useStyle({ hideLangToggle, ...props });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const showZendesk = typeof window?.zE === "function";

  return (
    <main className={classes.Wrapper}>
      {!hideLangToggle && <Toggle />}
      <Image
        image={isMobile ? "logoMed" : "logoLarge"}
        alt="logo"
        className={classes.LogoHeader}
      />
      <section className={classes.ContentWrapper}>{children}</section>
      {props.footer && <Footer />}
      {showZendesk && <ZendeskButton />}
    </main>
  );
};
export default Onboarding;
