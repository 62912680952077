import React, { useState, useEffect } from "react";

import { Row, Col } from "../../../components/Grid";
import { InputField } from "../../../components/Inputs";
import Button from "../../../components/Button";
import * as T from "../../../components/Typograpy";

import * as S from "./style";

import t from "../../../utils/helpers/translator";

import {
  fields,
  device,
  headlineComponents,
  subHeadlineComponents,
  initStates,
} from "./data";

const { firstName, lastName, email } = fields;

const MemberAccount = ({
  isMobile,
  onChange,
  handleClick,
  errors,
  showNotification,
  userAccount,
  isUpdating,
  btnOn,
  showResetPW,
  mainError,
  language,
}) => {
  const Headline = headlineComponents[device(isMobile)];
  const SubHeadline = subHeadlineComponents[device(isMobile)];

  const [state, setState] = useState({
    ...initStates.member,
  });

  // set state with user data
  useEffect(() => {
    if (userAccount.data) {
      setState(userAccount.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.AccountWrapper>
      <Row>
        <Col w={[4, 12, 12]}>
          <Headline color="midnight">{t("myAccount", language)}</Headline>
          <SubHeadline
            color="midnight"
            style={{
              marginTop: isMobile ? "2.5rem" : "3.5rem",
              fontWeight: "900",
            }}
          >
            {t("accountDetails", language)}
          </SubHeadline>
        </Col>
      </Row>

      {/* Fields */}
      <Row>
        <Col w={[4, 6, 6]}>
          <InputField
            mt={!isMobile ? 6 : 4}
            label={t("firstName", language)}
            type={firstName.type}
            placeholder={`${t("typeFirstName", language)}...`}
            name={firstName.name}
            setValue={(e) => onChange(e, setState, state)}
            value={state.firstName || ""}
            error={errors.firstName}
          />
        </Col>
        <Col w={[4, 6, 6]}>
          <InputField
            mt={!isMobile ? 6 : 4}
            label={t("lastName", language)}
            type={lastName.type}
            placeholder={`${t("typeLastName", language)}...`}
            name={lastName.name}
            setValue={(e) => onChange(e, setState, state)}
            value={state.lastName || ""}
            error={errors.lastName}
          />
        </Col>
      </Row>
      <Row>
        <Col w={[4, 6, 6]}>
          <InputField
            mt={!isMobile ? 6 : 4}
            label={t("email", language)}
            type={email.type}
            placeholder={`${t("typeEmail", language)}...`}
            name={email.name}
            setValue={(e) => onChange(e, setState, state)}
            value={state.email || ""}
            error={
              errors.email ||
              (mainError?.data?.field === "email" &&
                t(mainError.message, language))
            }
          />
        </Col>
        <Col
          style={{
            alignSelf: "center",
            paddingTop: isMobile ? "1rem" : "0.5rem",
          }}
          w={[4, 6, 6]}
        >
          {showResetPW()}
        </Col>
      </Row>
      <Row>
        <Col
          style={{
            marginTop: !isMobile ? "60px" : "40px",
          }}
          w={[4, 6, 6]}
        >
          <Button
            primary
            size="l"
            type="submit"
            handleClick={(e) => handleClick({ e, state })}
            loading={isUpdating}
            disabled={!btnOn}
          >
            {t("saveChanges", language)}
          </Button>
          {showNotification(t("changesSaved", language))}
        </Col>
      </Row>
      <S.DeleteWrapper>
        <T.Body14R
          style={{
            marginTop: isMobile ? "5rem" : "8rem",
            marginBottom: "10px",
            textAlign: isMobile ? "center" : "left",
          }}
          color="gray3"
        >
          {t("contactTempoToDelete", language)}
        </T.Body14R>
      </S.DeleteWrapper>
    </S.AccountWrapper>
  );
};

export default MemberAccount;
