import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col } from "../../../components/Grid";
import Button from "../../../components/Button";
import { InputField } from "../../../components/Inputs";

import { fields, device, headlineComponents, initStates } from "./data";

import t from "../../../utils/helpers/translator";

const { email } = fields;

const SpendVenueAccount = ({
  isMobile,
  onChange,
  handleClick,
  errors,
  showNotification,
  userAccount,
  isUpdating,
  btnOn,
  showResetPW,
  mainError,
  language,
}) => {
  const Headline = headlineComponents[device(isMobile)];

  const [state, setState] = useState({
    ...initStates.spendPartner,
  });

  // set state with user data
  useEffect(() => {
    if (userAccount.data) {
      setState(userAccount.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row>
        <Col w={[4, 12, 12]}>
          <Headline
            style={{
              fontSize: isMobile && "22px",
              lineHeight: isMobile && "22px",
            }}
            color="midnight"
          >
            {t("accountDetails", language)}
          </Headline>
        </Col>
      </Row>

      {/* Fields */}
      <Row>
        <Col w={[4, 6, 6]} style={{ marginBottom: !isMobile ? "0" : "15px" }}>
          <InputField
            mt={!isMobile ? 6 : 4}
            label={t("emailAddress", language)}
            type={email.type}
            placeholder={`${t("typeEmailHere", language)}...`}
            name={email.name}
            setValue={(e) => onChange(e, setState, state)}
            value={state.email || ""}
            error={
              errors.email ||
              (mainError?.data?.field === "email" &&
                t(mainError.message, language))
            }
          />
        </Col>
        <Col
          style={{
            alignSelf: "center",
            paddingTop: "0.5rem",
            paddingLeft: isMobile && "1rem",
          }}
          w={[4, 6, 6]}
        >
          {showResetPW()}
        </Col>
      </Row>
      <Row style={{ marginTop: !isMobile ? "60px" : "40px" }}>
        <Col w={[4, 6, 6]}>
          <Button
            primary
            size="l"
            type="submit"
            handleClick={(e) => handleClick({ e, state })}
            loading={isUpdating}
            disabled={!btnOn}
          >
            {t("saveChanges", language)}
          </Button>
          {showNotification(t("changesSaved", language))}
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({ language: state.auth.language });

export default connect(mapStateToProps)(SpendVenueAccount);
